import {
  betaDEV,
  dev,
  moonbaseAlpha,
  moonbaseBeta,
} from '@moonbeam-network/xcm-config';
import { MrlEvmParachain } from 'services/mrl/chains/classes/MrlEvmParachain';
import { mrlTestnetFtmWH } from 'services/mrl/tokens/chainTokens/testnet/parachainTestnetTokens';
import { fantomTestnet } from 'viem/chains';

export const moonbaseBetaMrlChain = new MrlEvmParachain({
  key: moonbaseBeta.key,
  name: moonbaseBeta.name,
  parachainId: moonbaseBeta.parachainId,
  genesisHash: moonbaseBeta.genesisHash,
  ss58Format: moonbaseBeta.ss58Format,
  ws: moonbaseBeta.ws,
  isTestChain: true,
  redeemChainId: moonbaseAlpha.id,
  redeemChainName: moonbaseAlpha.name,
  shouldUseSubstrateWallet: true,
  isAutomaticPossible: false, // ? has to be re-verified
  explorer: `https://polkadot.js.org/apps/?rpc=${encodeURIComponent(
    Array.isArray(moonbaseBeta.ws) ? moonbaseBeta.ws[0] : moonbaseBeta.ws,
  )}#/explorer/query`,
  destinations: [
    {
      chain: fantomTestnet.name,
      tokens: [mrlTestnetFtmWH],
      fees: [
        // TODO relate this to the fees used in the polkadot extrinsic builder (cross-chain fee + buy exectution fee)
        { asset: dev, min: 0.0013 },
        { asset: betaDEV, min: 0.0001 },
      ],
    },
  ],
  transferrableAssets: [
    {
      token: mrlTestnetFtmWH,
      destination: fantomTestnet.name,
      fees: [
        // TODO relate this to the fees used in the polkadot extrinsic builder (cross-chain fee + buy exectution fee)
        { asset: dev, min: 0.0013 },
      ],
      destinationFee: 0.0001,
    },
  ],
});
