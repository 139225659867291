export enum Wallet {
  Coinbase = 'Coinbase Wallet',
  Enkrypt = 'Enkrypt',
  Rabby = 'Rabby',
  MetaMask = 'MetaMask',
  NovaWallet = 'NovaWallet',
  Polkadot = 'Polkadot',
  SubWallet = 'SubWallet',
  Talisman = 'Talisman',
  WalletConnect = 'WalletConnect',
}

export const mobileWallets: Wallet[] = [
  Wallet.Coinbase,
  Wallet.MetaMask,
  Wallet.NovaWallet,
  Wallet.SubWallet,
  Wallet.WalletConnect,
];

export const walletToSubstrateExtensionMap: Partial<Record<Wallet, string>> = {
  [Wallet.Polkadot]: 'polkadot-js',
  [Wallet.SubWallet]: 'subwallet-js',
  [Wallet.Talisman]: 'talisman',
};

export const walletsCanAddTokens: Wallet[] = [Wallet.MetaMask, Wallet.Talisman];
