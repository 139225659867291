import { Group, Modal, Text } from '@mantine/core';
import { ActionButton } from 'components/ActionButton';
import { useGaEvents } from 'hooks/useSendButtonClickedEvent';
import { useTranslation } from 'next-i18next';
import { useOffsiteModal } from 'recoil/offsiteModal/hooks';
export const OffsiteModal = () => {
  const {
    t
  } = useTranslation();
  const {
    isOpen,
    url,
    close
  } = useOffsiteModal();
  const {
    sendUrlOpenedEvent
  } = useGaEvents();
  return <Modal opened={isOpen} onClose={close} title={t('offsiteLinkModal.title')} size={'lg'} data-sentry-element="Modal" data-sentry-component="OffsiteModal" data-sentry-source-file="OffsiteModal.tsx">
      <Text data-sentry-element="Text" data-sentry-source-file="OffsiteModal.tsx">{t('offsiteLinkModal.message')}</Text>
      <Group mt={'xl'} justify={'right'} data-sentry-element="Group" data-sentry-source-file="OffsiteModal.tsx">
        <ActionButton onClick={close} variant={'outline'} data-sentry-element="ActionButton" data-sentry-source-file="OffsiteModal.tsx">
          {t('cancel')}
        </ActionButton>
        <ActionButton component={'a'} href={url} target={'_blank'} rel={'noopener noreferrer'} onClick={event => {
        url && sendUrlOpenedEvent(url);
        close();
        event.preventDefault();
        window.open(url, '_blank', 'noopener,noreferrer');
      }} data-sentry-element="ActionButton" data-sentry-source-file="OffsiteModal.tsx">
          {t('offsiteLinkModal.confirm')}
        </ActionButton>
      </Group>
    </Modal>;
};