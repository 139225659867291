import { u16 } from '@polkadot/types';
import { PalletReferendaTrackInfo } from '@polkadot/types/lookup';
import { useApi } from 'hooks/useApi';
import { useMemo } from 'react';
import { calcThresholdCurves } from 'utils/charts';

export interface CurveGraph {
  approval: number[];
  support: number[];
  timeline: number[];
}

export const VALID_TRACKS = [
  'root',
  'whitelisted_caller',
  'general_admin',
  'referendum_canceller',
  'referendum_killer',
  'fast_general_admin',
];

export type Tracks = Map<string, TrackInfo>;

export interface TrackInfo {
  id: number;
  name: string;
  maxDeciding: number;
  decisionDeposit: bigint;
  preparePeriod: number;
  decisionPeriod: number;
  confirmPeriod: number;
  minEnactmentPeriod: number;
  chartData: CurveGraph;
}

function transformTracks(tracks?: [u16, PalletReferendaTrackInfo][]): Tracks {
  return new Map(
    tracks?.map(([id, info]) => [
      info.name.toString(),
      {
        id: id.toNumber(),
        name: info.name.toString(),
        maxDeciding: info.maxDeciding.toNumber(),
        decisionDeposit: info.decisionDeposit.toBigInt(),
        preparePeriod: info.preparePeriod.toNumber(),
        decisionPeriod: info.decisionPeriod.toNumber(),
        confirmPeriod: info.confirmPeriod.toNumber(),
        minEnactmentPeriod: info.minEnactmentPeriod.toNumber(),
        chartData: calcThresholdCurves(info),
      },
    ]),
  );
}

export function useTracks(): Tracks | undefined {
  const api = useApi();

  return useMemo(() => {
    const tracks = api?.consts.referenda?.tracks as
      | [u16, PalletReferendaTrackInfo][]
      | undefined;

    if (tracks) {
      return transformTracks(tracks);
    }
  }, [api]);
}

export function useTrack(trackId: string | undefined): TrackInfo | undefined {
  const tracks = useTracks();

  return trackId ? tracks?.get(trackId) : undefined;
}
