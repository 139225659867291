import { ActionIcon, Container, Divider, Group } from '@mantine/core';
import { OffsiteLink } from 'components/OffsiteLink';
import { useTranslation } from 'next-i18next';
import { IoLogoGithub } from 'react-icons/io5';
import { DarkModeToggle } from './DarkModeToggle';
import { UserSettingsModal } from './UserSettingsModal';
export const NavbarSettings = () => {
  const {
    t
  } = useTranslation();
  return <Container data-sentry-element="Container" data-sentry-component="NavbarSettings" data-sentry-source-file="NavbarSettings.tsx">
      <Divider my={'sm'} data-sentry-element="Divider" data-sentry-source-file="NavbarSettings.tsx" />
      <Group justify={'center'} pb={16} px={8} data-sentry-element="Group" data-sentry-source-file="NavbarSettings.tsx">
        <OffsiteLink link={'https://github.com/moonbeam-foundation/moonbeam'} aria-label={t('icons.github')} withIcon={false} data-sentry-element="OffsiteLink" data-sentry-source-file="NavbarSettings.tsx">
          <ActionIcon title={t('icons.github')} data-sentry-element="ActionIcon" data-sentry-source-file="NavbarSettings.tsx">
            <IoLogoGithub size={20} data-sentry-element="IoLogoGithub" data-sentry-source-file="NavbarSettings.tsx" />
          </ActionIcon>
        </OffsiteLink>
        <UserSettingsModal data-sentry-element="UserSettingsModal" data-sentry-source-file="NavbarSettings.tsx" />
        <DarkModeToggle data-sentry-element="DarkModeToggle" data-sentry-source-file="NavbarSettings.tsx" />
      </Group>
    </Container>;
};