import { Modal } from '@mantine/core';
import { ConnectContent } from './ConnectContent';
import classes from './ConnectModal.module.css';
export interface Props {
  isOpen: boolean;
  onClose: () => void;
}
export const ConnectModal = ({
  isOpen,
  onClose
}: Props) => {
  return <Modal opened={isOpen} onClose={onClose} size={'95%'} classNames={{
    close: classes.close,
    header: classes.header
  }} padding={0}
  // zIndex needed to not render on top of WalletConnect
  zIndex={80} data-sentry-element="Modal" data-sentry-component="ConnectModal" data-sentry-source-file="ConnectModal.tsx">
      <ConnectContent data-sentry-element="ConnectContent" data-sentry-source-file="ConnectModal.tsx" />
    </Modal>;
};