import { agng, ftmwh } from '@moonbeam-network/xcm-config';
import { ParachainToken } from 'services/mrl/chains/classes/BaseChain';
import { moonbaseAlphaWFTM } from 'services/mrl/tokens/chainTokens';
import { moonbaseAlphaAgng } from 'services/mrl/tokens/chainTokens/testnet/moonbaseAlpha';

export const mrlTestnetFtmWH: ParachainToken = {
  asset: ftmwh,
  moonToken: moonbaseAlphaWFTM,
};

export const mrlTestnetAgng: ParachainToken = {
  asset: agng,
  moonToken: moonbaseAlphaAgng,
};
