import {
  moonbaseConfig,
  moonbeamConfig,
  moonriverConfig,
} from 'configs/chains';
import { Wallet } from 'lib/wallets';
import {
  Chain,
  moonbaseAlpha as moonbaseAlphaViem,
  moonbeam as moonbeamViem,
  moonriver as moonriverViem,
} from 'viem/chains';
import { Connector } from 'wagmi';

/**
 * overrides the rpc urls
 */
export function getMoonChains(): [Chain, ...Chain[]] {
  const moonbeam: Chain = {
    ...moonbeamViem,
    rpcUrls: {
      default: {
        http: moonbeamConfig.rpcUrls,
      },
    },
  };
  const moonriver: Chain = {
    ...moonriverViem,
    rpcUrls: {
      default: {
        http: moonriverConfig.rpcUrls,
      },
    },
  };
  const moonbaseAlpha: Chain = {
    ...moonbaseAlphaViem,
    rpcUrls: {
      default: {
        http: moonbaseConfig.rpcUrls,
      },
    },
  };

  return [moonbeam, moonriver, moonbaseAlpha];
}

export function isCoinbaseConnector(connector: Connector): boolean {
  return connector.name === Wallet.Coinbase;
}
