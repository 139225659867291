import { useFlags } from 'flagsmith/react';
import { useMoonChainKeyParam } from 'hooks/useMoonChainParam';
import { parseJson } from 'utils/json';

export function useFeatureByChain(feature: string): string | undefined {
  const chain = useMoonChainKeyParam();
  const { [feature]: flag } = useFlags([feature]);

  if (!flag.enabled) return undefined;

  const value = parseJson(flag.value);

  return value[chain];
}
