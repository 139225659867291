import { CSSVariablesResolver, darken, lighten } from '@mantine/core';

export const cssVariablesResolver: CSSVariablesResolver = (theme) => {
  const { other } = theme;

  return {
    variables: {
      '--mantine-color-action': other.colors.action,
      '--mantine-color-action-hover': lighten(other.colors.action, 0.05),
      '--mantine-gradient-announcements-regular':
        other.colors.announcements.regular,
      '--mantine-gradient-announcements-important':
        other.colors.announcements.important,
      '--mantine-color-announcements-text-regular':
        other.colors.announcementsText.regular,
      '--mantine-color-announcements-text-important':
        other.colors.announcementsText.important,
      '--mantine-font-family-title': 'Varela Round',
      '--mantine-color-contrastButton': other.colors.contrastButton,
      '--mantine-radius-default': theme.radius.md,
    },
    light: {
      '--mantine-color-navbarBackground':
        other.themeColors.navbarBackground.light,
      '--mantine-color-secondary': other.themeColors.secondary.light,
      '--mantine-color-secondary-hover': darken(
        other.themeColors.secondary.light,
        0.05,
      ),
      '--mantine-color-texts': other.themeColors.texts.light,
      '--mantine-color-whiteDark': other.themeColors.whiteDark.light,
      '--mantine-color-whiteDark-hover': darken(
        other.themeColors.whiteDark.light,
        0.01,
      ),
      '--mantine-color-placeholder': other.themeColors.placeholder.light,
      '--mantine-color-disabled': other.themeColors.disabled.light,
      '--mantine-color-greyBorder': other.themeColors.greyBorder.light,
      '--mantine-color-footerShadow': other.themeColors.footerShadow.light,
      '--table-striped-color': other.themeColors.secondary.light,
    },
    dark: {
      '--mantine-color-navbarBackground':
        other.themeColors.navbarBackground.dark,
      '--mantine-color-secondary': other.themeColors.secondary.dark,
      '--mantine-color-secondary-hover': lighten(
        other.themeColors.secondary.dark,
        0.05,
      ),
      '--mantine-color-texts': other.themeColors.texts.dark,
      '--mantine-color-whiteDark': other.themeColors.whiteDark.dark,
      '--mantine-color-whiteDark-hover': lighten(
        other.themeColors.whiteDark.dark,
        0.05,
      ),
      '--mantine-color-placeholder': other.themeColors.placeholder.dark,
      '--mantine-color-disabled': other.themeColors.disabled.dark,
      '--mantine-color-greyBorder': other.themeColors.greyBorder.dark,
      '--mantine-color-footerShadow': other.themeColors.footerShadow.dark,
      '--table-striped-color': other.themeColors.secondary.dark,
    },
  };
};
