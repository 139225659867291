import { Header } from '@polkadot/types/interfaces';
import { useApi } from 'hooks/useApi';

import { useApiCall } from 'hooks/useApiCall';

export interface Round {
  first: number;
  current: number;
  length: number;
}

function transFormLatestBlock(data: Header): number {
  return data.number.toBn().toNumber();
}

export function useLatestBlock(): number | undefined {
  const api = useApi();

  return useApiCall(api?.rpc.chain.subscribeNewHeads, [], transFormLatestBlock);
}
