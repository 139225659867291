import { DefaultValue, selector } from 'recoil';
import { createSourceFilter } from 'recoil/substrateExtension/utils';
import {
  substrateAccountsAtom,
  substrateAddressAtom,
  substrateSelectedExtensionAtom,
} from './atoms';

export const withSubstrateExtension = selector<string | undefined>({
  key: 'withSubstrateExtension',
  get: ({ get }) => {
    return get(substrateSelectedExtensionAtom);
  },
  set: ({ set, get }, newValue) => {
    set(
      substrateSelectedExtensionAtom,
      newValue instanceof DefaultValue ? undefined : newValue,
    );

    if (newValue instanceof DefaultValue || !newValue) {
      set(substrateAddressAtom, undefined);

      return;
    }

    const accounts = get(substrateAccountsAtom);
    const account = accounts.filter(createSourceFilter(newValue)).at(0);

    set(substrateAddressAtom, account?.address);
  },
});

export const withSubstrateExtensionAccounts = selector({
  key: 'withSubstrateExtensionAccounts',
  get: ({ get }) => {
    const extension = get(substrateSelectedExtensionAtom);
    const accounts = get(substrateAccountsAtom);

    if (!extension) {
      return [];
    }

    return accounts.filter(createSourceFilter(extension));
  },
});
