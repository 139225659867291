import { AssetAmount } from '@moonbeam-network/xcm-types';
import Big from 'big.js';
import { Round } from 'hooks/queries/useRound';

export interface RemainingTime {
  number: string;
  label: string;
}

export function calcTimePerRound(round: Round, blockTime: number): number {
  return (round.length * blockTime) / 3600;
}

export function calcRewardDelayTime(
  round: Round,
  blockTime: number,
  rewardPaymentDelay = 0,
): number {
  return calcTimePerRound(round, blockTime) * rewardPaymentDelay;
}

export function calcTokenConversion(
  assetAmount: AssetAmount,
  rate: number,
  maxDecimals?: number,
): number {
  return assetAmount.toBigDecimal(maxDecimals).mul(rate).toNumber();
}

export function calcBlockRemainingTime(
  latestBlock: number,
  targetBlock: number,
  blockTime: number,
): number {
  if (latestBlock >= targetBlock) return 0;

  const startDate: Date = new Date();
  const endDate: Date = new Date(
    (targetBlock - latestBlock) * blockTime * 1000 + Date.now(),
  );

  return endDate.getTime() - startDate.getTime();
}

export function calcHistoricalRewardRate(
  rewards: bigint,
  staked?: bigint,
): string | undefined {
  if (!staked) return;

  const rewardsPerWeek = Big(rewards.toString());
  const stakedAmount = Big(staked.toString());

  const historicalRewardRate = rewardsPerWeek
    .mul(52)
    .div(stakedAmount)
    .mul(100);

  return historicalRewardRate.toFixed(2);
}

export function calcPercentage(value: bigint, total: bigint): number {
  if (total === 0n) {
    throw new Error('Total cannot be zero');
  }

  const bigTotal = Big(total.toString());
  const bigValue = Big(value.toString());

  return bigValue.mul(Big(100)).div(bigTotal).toNumber();
}

export function calcProgressValue(value?: bigint, total?: bigint): number {
  if (!value || !total || total === 0n) {
    return 0;
  }

  const percentage = calcPercentage(value, total);

  // we display a minimum to make it more visually appealing
  const min = 5;

  return percentage !== 0 && percentage < min ? min : percentage;
}

export function calcVotePercentage(value: bigint, total: bigint): number {
  // if no votes, return 50/50
  if (!total || total === 0n) {
    return 50;
  }

  return calcPercentage(value, total);
}

export function calcBlockProgressPercentage(
  startBlock?: number,
  currentBlock?: number,
  lastBlock?: number,
): number | undefined {
  if (!lastBlock || !currentBlock || !startBlock) {
    return undefined;
  }

  return calcPercentage(BigInt(currentBlock - startBlock), BigInt(lastBlock));
}

export function calcRemainingBlocks(
  startBlock?: number,
  currentBlock?: number,
  lastBlock?: number,
): number | undefined {
  if (!lastBlock || !currentBlock || !startBlock) {
    return undefined;
  }

  const difference = lastBlock - (currentBlock - startBlock);

  return difference > 0 ? difference : undefined;
}

export function bigMin(...values: Big[]): Big {
  return values.reduce((min, value) => (value.lt(min) ? value : min));
}

export function bigMax(...values: Big[]): Big {
  return values.reduce((max, value) => (value.gt(max) ? value : max));
}
