import {
  AnyChain,
  Asset,
  AssetAmount,
  EvmParachain,
  EvmParachainConstructorParams,
  Parachain,
} from '@moonbeam-network/xcm-types';
import { formatAssetIdToERC20 } from '@moonbeam-network/xcm-utils';
import { isString } from '@polkadot/util';

export interface MoonChainConstructorParams
  extends EvmParachainConstructorParams {
  asset: Asset;
  blockTime: number;
  explorerUrls: string[];
  minBalance: bigint;
  polkassemblyOpenGovUrl?: string;
  polkassemblyApiParam?: string;
  polkassemblyUrl: string;
  relay: Parachain;
  rpcUrls: string[];
  subscanApiUrl: string;
  subscanUrl: string;
}

export class MoonChain extends EvmParachain {
  asset: Asset;
  blockTime: number;
  decimals = 18;
  explorerUrls: string[];
  minBalance: bigint;
  polkassemblyOpenGovUrl?: string;
  polkassemblyApiParam: string;
  polkassemblyUrl: string;
  relay: Parachain;
  rpcUrls: string[];
  subscanApiUrl: string;
  subscanUrl: string;

  constructor({
    asset,
    blockTime,
    explorerUrls,
    minBalance,
    polkassemblyOpenGovUrl,
    polkassemblyUrl,
    polkassemblyApiParam,
    relay,
    rpcUrls,
    subscanApiUrl,
    subscanUrl,
    ...others
  }: MoonChainConstructorParams) {
    super(others);

    this.asset = asset;
    this.blockTime = blockTime;
    this.explorerUrls = explorerUrls;
    this.minBalance = minBalance;
    this.polkassemblyOpenGovUrl = polkassemblyOpenGovUrl;
    this.polkassemblyUrl = polkassemblyUrl;
    this.polkassemblyApiParam = polkassemblyApiParam || this.key;
    this.relay = relay;
    this.rpcUrls = rpcUrls;
    this.subscanApiUrl = subscanApiUrl;
    this.subscanUrl = subscanUrl;
  }

  isNative(asset: Asset | AssetAmount): boolean {
    return this.asset.key === asset.key;
  }

  isRelayChain(chain: AnyChain): boolean {
    return this.relay.key === chain.key;
  }

  getErc20Id(asset: Asset | AssetAmount): string {
    const id = this.getAssetId(asset);

    if (this.isNative(asset) && isString(id)) {
      return id;
    }

    if (!isString(id)) {
      throw new Error(`Can't get ERC20 id for asset ${asset.key}`);
    }

    return formatAssetIdToERC20(id);
  }
}
