import { Box, Group, Loader, Text, UnstyledButton, UnstyledButtonProps } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import cx from 'clsx';
import { useMoonChainKeyParam } from 'hooks/useMoonChainParam';
import { useThemeColors } from 'hooks/useThemeColors';
import { useThemeLogos } from 'hooks/useThemeLogos';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useRecoilState } from 'recoil';
import { isMoonChainSelectionOpenAtom } from 'recoil/isMoonChainSelectionOpen';
import { MoonChain } from 'types/MoonChain';
import { getChangeMoonChainPath } from 'utils/routes';
import { useAccount, useSwitchChain } from 'wagmi';
import classes from './MoonChainButton.module.css';
export interface Props extends UnstyledButtonProps {
  chain: MoonChain;
  withAddButton?: boolean;
}
export const MoonChainButton = ({
  chain,
  classNames,
  className,
  withAddButton,
  ...other
}: Props) => {
  const router = useRouter();
  const {
    t
  } = useTranslation();
  const {
    connector
  } = useAccount();
  const [, setSelectedMoonChain] = useLocalStorage<string>({
    key: 'selected-moon-chain'
  });
  const {
    isPending,
    switchChain
  } = useSwitchChain();
  // biome-ignore lint/suspicious/noDoubleEquals: <explanation>
  const isSelectedMoonChain = useMoonChainKeyParam() == chain.key;
  const [, setIsMoonChainModalOpen] = useRecoilState(isMoonChainSelectionOpenAtom);
  const {
    themeColors
  } = useThemeColors();
  const logo = useThemeLogos(chain.key);
  const changeNetwork = () => {
    switchChain?.({
      chainId: chain.id
    });
    setSelectedMoonChain(chain.key);
    setIsMoonChainModalOpen(false);
    router.push({
      pathname: getChangeMoonChainPath(router.pathname),
      query: {
        moonChainKey: chain.key
      }
    });
  };
  const renderSelectedOrLoader = () => {
    if (isPending) {
      return <Loader size={'sm'} />;
    }
    if (isSelectedMoonChain) {
      return <Text size={'xs'} c={themeColors.secondaryTexts} tt={'lowercase'}>
          {t('selected')}
        </Text>;
    }
    return null;
  };
  return <Box className={cx(classes.root, className)} {...other} data-sentry-element="Box" data-sentry-component="MoonChainButton" data-sentry-source-file="MoonChainButton.tsx">
      <UnstyledButton className={cx(classes.button, {
      [classes.selected]: isSelectedMoonChain
    })} classNames={classNames} onClick={changeNetwork} data-sentry-element="UnstyledButton" data-sentry-source-file="MoonChainButton.tsx">
        <Group justify={'space-between'} data-sentry-element="Group" data-sentry-source-file="MoonChainButton.tsx">
          <Image src={logo} alt={chain.name} width={116} height={24} style={{
          objectFit: 'contain'
        }} priority data-sentry-element="Image" data-sentry-source-file="MoonChainButton.tsx" />
          {renderSelectedOrLoader()}
        </Group>
      </UnstyledButton>
      <UnstyledButton ml={'auto'} className={withAddButton ? classes.addButtonVisible : classes.addButtonHidden} data-sentry-element="UnstyledButton" data-sentry-source-file="MoonChainButton.tsx">
        <Text size={'xs'} data-sentry-element="Text" data-sentry-source-file="MoonChainButton.tsx">{`${t('addTo')} ${connector?.name}`}</Text>
      </UnstyledButton>
    </Box>;
};