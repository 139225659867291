import { AppShell, Center, Container } from '@mantine/core';
import { OpenMenuButton } from 'components/AppShell/OpenMenuButton';
import { useIsMobile } from 'hooks/useMedia';
import classes from './Navbar.module.css';
import { NavbarLogo } from './NavbarLogo';
import { NavbarMenu } from './NavbarMenu';
import { NavbarSettings } from './NavbarSettings';
export const Navbar = () => {
  const isMobile = useIsMobile();
  return <AppShell.Navbar zIndex={10} withBorder={false} className={classes.navbar} data-sentry-element="unknown" data-sentry-component="Navbar" data-sentry-source-file="Navbar.tsx">
      <AppShell.Section data-sentry-element="unknown" data-sentry-source-file="Navbar.tsx">
        <Container pt={isMobile ? 'md' : 0} data-sentry-element="Container" data-sentry-source-file="Navbar.tsx">
          <OpenMenuButton data-sentry-element="OpenMenuButton" data-sentry-source-file="Navbar.tsx" />
          <Center data-sentry-element="Center" data-sentry-source-file="Navbar.tsx">
            <NavbarLogo data-sentry-element="NavbarLogo" data-sentry-source-file="Navbar.tsx" />
          </Center>
        </Container>
      </AppShell.Section>
      <AppShell.Section grow={!isMobile} className={classes.scrollable} data-sentry-element="unknown" data-sentry-source-file="Navbar.tsx">
        <NavbarMenu data-sentry-element="NavbarMenu" data-sentry-source-file="Navbar.tsx" />
      </AppShell.Section>
      <AppShell.Section data-sentry-element="unknown" data-sentry-source-file="Navbar.tsx">
        <NavbarSettings data-sentry-element="NavbarSettings" data-sentry-source-file="Navbar.tsx" />
      </AppShell.Section>
    </AppShell.Navbar>;
};