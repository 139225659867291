import { Button, Group, Modal } from '@mantine/core';
import { ActionButton } from 'components/ActionButton';
import { CookieConsentModalText } from 'components/CookieConsentModal/CookieConsentModalText';
import { useCookiesAccepted } from 'hooks/useCookiesAccepted';
import { useIsMounted } from 'hooks/useIsMounted';
import { useIsMobile } from 'hooks/useMedia';
import { useThemeColors } from 'hooks/useThemeColors';
import { useTranslation } from 'next-i18next';
export function CookieConsentModal() {
  const {
    t
  } = useTranslation();
  const isMobile = useIsMobile();
  const isMounted = useIsMounted();
  const {
    themeColors
  } = useThemeColors();
  const {
    isCookieAccepted,
    setCookieAccepted,
    setCookieRejected
  } = useCookiesAccepted();
  return <Modal size={'xl'} padding={'xl'} opened={isMounted && isCookieAccepted === undefined} withCloseButton={false} onClose={() => undefined} yOffset={isMobile ? '15vh' : undefined} centered data-sentry-element="Modal" data-sentry-component="CookieConsentModal" data-sentry-source-file="CookieConsentModal.tsx">
      <CookieConsentModalText data-sentry-element="CookieConsentModalText" data-sentry-source-file="CookieConsentModal.tsx" />
      <Group mt={'xs'} justify={'flex-end'} data-sentry-element="Group" data-sentry-source-file="CookieConsentModal.tsx">
        <>
          <Button style={{
          color: themeColors.texts
        }} onClick={setCookieRejected} data-sentry-element="Button" data-sentry-source-file="CookieConsentModal.tsx">
            {t('rejectAllCookies')}
          </Button>
          <ActionButton onClick={setCookieAccepted} data-sentry-element="ActionButton" data-sentry-source-file="CookieConsentModal.tsx">
            {t('acceptAllCookies')}
          </ActionButton>
        </>
      </Group>
    </Modal>;
}