import { AssetAmount } from '@moonbeam-network/xcm-types';
import {
  FrameSystemAccountInfo,
  PalletBalancesBalanceLock,
} from '@polkadot/types/lookup';
import { useAddress } from 'hooks/useAddress';
import { useApi } from 'hooks/useApi';
import { useApiCall } from 'hooks/useApiCall';
import { useMoonChain } from 'hooks/useMoonChainConfig';
import { useMemo } from 'react';

interface SystemAccountTransform {
  total: bigint;
  free: bigint;
  availableForStaking: bigint;
  availableForVoting: bigint;
}

interface LocksTransform {
  lockedStaking: bigint;
  lockedDemocracy: bigint;
}

export interface Balances extends SystemAccountTransform, LocksTransform {}

function transformSystemAccount(
  value?: FrameSystemAccountInfo,
): SystemAccountTransform | undefined {
  if (!value) {
    return undefined;
  }

  const free = value.data.free.toBigInt();
  const reserved = value.data.reserved.toBigInt();
  const frozen = value.data.frozen.toBigInt();

  const locked = frozen - reserved > 0n ? frozen - reserved : 0n;

  return {
    total: free + reserved,
    free: free - locked,
    availableForStaking: free,
    availableForVoting: free,
  };
}

function transformLocks(value: PalletBalancesBalanceLock[]): LocksTransform {
  const locks = {
    lockedStaking: 0n,
    lockedDemocracy: 0n,
  };

  value.forEach((lock) => {
    if (lock.id.eq('stkngdel')) locks.lockedStaking = lock.amount.toBigInt();
    if (lock.id.eq('democrac')) locks.lockedDemocracy += lock.amount.toBigInt();
    if (lock.id.eq('pyconvot')) locks.lockedDemocracy += lock.amount.toBigInt();
  });

  return locks;
}

// this gets balances from Moonbeam, Moonriver, MoonbaseAlpha
export function useMoonBalances(address?: string): Balances | undefined {
  const api = useApi();
  const walletAddress = useAddress();
  const addressToUse = address || walletAddress;

  const locks = useApiCall(
    api?.query.balances.locks,
    [addressToUse],
    transformLocks,
  );
  const accountInfo = useApiCall(
    api?.query.system.account,
    [addressToUse],
    transformSystemAccount,
  );

  return useMemo(() => {
    if (!accountInfo || !locks) {
      return undefined;
    }

    return {
      ...accountInfo,
      ...locks,
      availableForStaking:
        accountInfo.availableForStaking - locks.lockedStaking,
    };
  }, [accountInfo, locks]);
}

export function useMoonAssetFreeBalance(
  address?: string,
): AssetAmount | undefined {
  const moonChain = useMoonChain();
  const balances = useMoonBalances(address);

  return useMemo(() => {
    if (!moonChain) {
      return undefined;
    }

    return AssetAmount.fromAsset(moonChain.asset, {
      amount: balances?.free || 0n,
      decimals: moonChain.decimals,
    });
  }, [balances, moonChain]);
}
