import { useLocalStorage } from '@mantine/hooks';
import { useMemo } from 'react';

type UseCookiesAcceptedResult = {
  isCookieAccepted: boolean | undefined;
  setCookieAccepted: () => void;
  setCookieRejected: () => void;
};

export function useCookiesAccepted(): UseCookiesAcceptedResult {
  const [cookiesAccepted, setCookiesAccepted] = useLocalStorage<string | false>(
    {
      key: 'cookies_accepted',
    },
  );

  const setCookieAccepted = (): void => {
    const date = new Date();

    date.setMonth(date.getMonth() + 12);
    setCookiesAccepted(date.toISOString());
  };

  const setCookieRejected = (): void => {
    setCookiesAccepted(false);
  };

  const isCookieAccepted = useMemo(() => {
    if (cookiesAccepted === false) return false;

    if (cookiesAccepted) {
      const isDateInFuture = new Date(cookiesAccepted) > new Date();

      return isDateInFuture ? true : undefined;
    }

    return undefined;
  }, [cookiesAccepted]);

  return {
    isCookieAccepted,
    setCookieAccepted,
    setCookieRejected,
  };
}
