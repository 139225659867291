import { MantineColorScheme, MantineProvider, localStorageColorSchemeManager } from '@mantine/core';
import '@mantine/carousel/styles.css';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/tiptap/styles.css';
import '@mantine/charts/styles.css';
import { Notifications } from '@mantine/notifications';
import { GoogleAnalytics } from '@next/third-parties/google';
import '@polkadot/types-augment/lookup';
import { AppShell } from 'components/AppShell';
import { CookieConsentModal } from 'components/CookieConsentModal';
import { TermsModal } from 'components/TermsModal';
import { WalletSetup } from 'components/WalletSetup';
import flagsmith from 'flagsmith/isomorphic';
import { FlagsmithProvider } from 'flagsmith/react';
import { IState } from 'flagsmith/types';
import { useCookiesAccepted } from 'hooks/useCookiesAccepted';
import { useMainTheme } from 'hooks/useMainTheme';
import { cssVariablesResolver } from 'lib/themes/cssVariablesResolver';
import { GetServerSideProps, NextComponentType } from 'next';
import { appWithTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { ApiProvider } from 'providers/ApiProvider';
import { EvmWalletProvider } from 'providers/EvmWalletProvider';
import { RecoilRoot } from 'recoil';
import { trpc } from 'utils/trpc/trpc';
import nextI18NextConfig from '../next-i18next.config.cjs';
import '../styles/global.css';
interface Props extends AppProps {
  persistedColorScheme: MantineColorScheme;
  flagsmithState: IState<string>;
}
const NEXT_PUBLIC_FLAGSMITH_ENV_ID = process.env.NEXT_PUBLIC_FLAGSMITH_ENV_ID || '';
const FLAGSMITH_ENABLED = process.env.FLAGSMITH_ENABLED || '';
const NEXT_PUBLIC_GOOGLE_ANALYTICS_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID;
function App({
  Component,
  pageProps,
  flagsmithState
}: Props) {
  const colorSchemeManager = localStorageColorSchemeManager({
    key: 'mantine-color-scheme'
  });
  const theme = useMainTheme();
  const {
    isCookieAccepted
  } = useCookiesAccepted();
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
        <title>{'Moonbeam Dapps'}</title>
        <meta name={'viewport'} content={'minimum-scale=1, initial-scale=1, width=device-width'} data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
      </Head>
      <ApiProvider data-sentry-element="ApiProvider" data-sentry-source-file="_app.tsx">
        <FlagsmithProvider serverState={flagsmithState} flagsmith={flagsmith} data-sentry-element="FlagsmithProvider" data-sentry-source-file="_app.tsx">
          <MantineProvider theme={theme} colorSchemeManager={colorSchemeManager} cssVariablesResolver={cssVariablesResolver} data-sentry-element="MantineProvider" data-sentry-source-file="_app.tsx">
            <RecoilRoot data-sentry-element="RecoilRoot" data-sentry-source-file="_app.tsx">
              <Notifications position={'top-right'} limit={5} autoClose={4000} data-sentry-element="Notifications" data-sentry-source-file="_app.tsx" />
              <EvmWalletProvider data-sentry-element="EvmWalletProvider" data-sentry-source-file="_app.tsx">
                <TermsModal data-sentry-element="TermsModal" data-sentry-source-file="_app.tsx" />
                <CookieConsentModal data-sentry-element="CookieConsentModal" data-sentry-source-file="_app.tsx" />
                <WalletSetup data-sentry-element="WalletSetup" data-sentry-source-file="_app.tsx" />

                <AppShell data-sentry-element="AppShell" data-sentry-source-file="_app.tsx">
                  <Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
                </AppShell>
              </EvmWalletProvider>
            </RecoilRoot>
          </MantineProvider>
        </FlagsmithProvider>
      </ApiProvider>
      {NEXT_PUBLIC_GOOGLE_ANALYTICS_ID && isCookieAccepted && <GoogleAnalytics gaId={NEXT_PUBLIC_GOOGLE_ANALYTICS_ID} />}
    </>;
}
const AppWithTRPC: NextComponentType = trpc.withTRPC(App);

// Modify Flagsmith initialization to work with tRPC
AppWithTRPC.getInitialProps = async () => {
  if (FLAGSMITH_ENABLED === 'false') {
    console.warn(`Flagsmith is disabled, FLAGSMITH_ENABLED: ${FLAGSMITH_ENABLED}, typeof FLAGSMITH_ENABLED: ${typeof FLAGSMITH_ENABLED}`);
    return {
      flagsmithState: {}
    };
  } else {
    await flagsmith.init({
      environmentID: NEXT_PUBLIC_FLAGSMITH_ENV_ID
    });
    return {
      flagsmithState: flagsmith.getState()
    };
  }
};
export const getServerSideProps: GetServerSideProps = async ({
  locale
}) => {
  return {
    props: {
      ...(await serverSideTranslations(locale as string, ['common'], nextI18NextConfig))
    }
  };
};

// Export AppWithTRPC instead of App

export default appWithTranslation(AppWithTRPC as React.ComponentType<Props>, nextI18NextConfig);