import { MrlChainType } from 'services/mrl/chains/classes/BaseChain';
import {
  MrlParachain,
  ParachainConstructorParams,
} from 'services/mrl/chains/classes/MrlParachain';
import { Except } from 'type-fest';
import { Address } from 'viem';

export interface EvmParachainConstructorParams
  extends Except<ParachainConstructorParams, 'isEvm' | 'type'> {
  shouldUseSubstrateWallet?: boolean;
  chainId?: number;
  moonTokenAddress?: Address; // TODO: not sure if good idea to have it here, It's for DEV/GLMR on the Evm Parachain
}

export class MrlEvmParachain extends MrlParachain {
  private shouldUseSubstrateWallet: boolean;
  readonly chainId?: number;
  readonly moonTokenAddress?: Address;

  constructor({
    shouldUseSubstrateWallet = false,
    chainId,
    moonTokenAddress,
    ...others
  }: EvmParachainConstructorParams) {
    super({ ...others, isEvm: true, type: MrlChainType.EvmParachain });

    this.shouldUseSubstrateWallet = shouldUseSubstrateWallet;
    this.chainId = chainId;
    this.moonTokenAddress = moonTokenAddress;
  }

  useSubstrateWallet(): boolean {
    return this.shouldUseSubstrateWallet ?? false;
  }
}
