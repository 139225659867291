import { Box, BoxProps } from '@mantine/core';
import ReactJazzicon, { jsNumberForAddress } from 'react-jazzicon';
export interface Props extends BoxProps {
  address?: string;
  size?: number;
  paperStyles?: object;
  svgStyles?: object;
}
export const Jazzicon = ({
  address = '',
  className,
  size = 52,
  paperStyles = {},
  svgStyles = {},
  ...others
}: Props) => {
  return <Box className={className} w={size} h={size} {...others} data-sentry-element="Box" data-sentry-component="Jazzicon" data-sentry-source-file="Jazzicon.tsx">
      <ReactJazzicon diameter={size} paperStyles={paperStyles} svgStyles={svgStyles} seed={jsNumberForAddress(address)} data-sentry-element="ReactJazzicon" data-sentry-source-file="Jazzicon.tsx" />
    </Box>;
};