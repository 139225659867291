import { Box, Container, Group, AppShell as MantineShell, useMantineTheme } from '@mantine/core';
import { Footer } from 'components/AppShell/Footer';
import { Navbar } from 'components/AppShell/Navbar';
import { ChangeMoonChainModal } from 'components/AppShell/Navbar/ChangeMoonChainModal';
import { OpenMenuButton } from 'components/AppShell/OpenMenuButton';
import { ConnectWallet } from 'components/ConnectWallet';
import { MaintenanceBanner } from 'components/MaintenanceBanner';
import { OffsiteModal } from 'components/OffsiteLink/OffsiteModal';
import { useIsMounted } from 'hooks/useIsMounted';
import { useIsMobile } from 'hooks/useMedia';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isMoonChainSelectionOpenAtom } from 'recoil/isMoonChainSelectionOpen';
import { isNavbarCollapsedAtom } from 'recoil/isNavbarCollapsed';
import { useMobileNavbar } from 'recoil/mobileNavbar';
import { useInitSubstrateExtension } from 'recoil/substrateExtension/hooks';
interface Props {
  children: React.ReactNode;
}
export const AppShell = ({
  children
}: Props) => {
  const theme = useMantineTheme();
  const [isMoonChainModalOpen, setIsMoonChainModalOpen] = useRecoilState(isMoonChainSelectionOpenAtom);
  const isMobile = useIsMobile();
  const isMounted = useIsMounted();
  const isNavbarCollapsed = useRecoilValue(isNavbarCollapsedAtom);
  const expandedWidth = 258;
  const contractedWidth = 80;
  const navbarWidth = {
    sm: isNavbarCollapsed ? contractedWidth : expandedWidth,
    lg: isNavbarCollapsed ? 0 : expandedWidth
  };
  const {
    isOpen
  } = useMobileNavbar();
  useInitSubstrateExtension();
  if (!isMounted) return null;
  return <>
      <OffsiteModal data-sentry-element="OffsiteModal" data-sentry-source-file="AppShell.tsx" />
      <ChangeMoonChainModal isOpen={isMoonChainModalOpen} setIsOpen={setIsMoonChainModalOpen} data-sentry-element="ChangeMoonChainModal" data-sentry-source-file="AppShell.tsx" />
      <MantineShell padding={'md'} navbar={{
      width: navbarWidth,
      breakpoint: 'sm',
      collapsed: {
        mobile: !isOpen
      }
    }} footer={{
      height: 29
    }} data-sentry-element="MantineShell" data-sentry-source-file="AppShell.tsx">
        <Box pt={'lg'} pl={'lg'} hiddenFrom={'sm'} data-sentry-element="Box" data-sentry-source-file="AppShell.tsx">
          <OpenMenuButton data-sentry-element="OpenMenuButton" data-sentry-source-file="AppShell.tsx" />
        </Box>
        <Navbar data-sentry-element="Navbar" data-sentry-source-file="AppShell.tsx" />
        <MantineShell.Main data-sentry-element="unknown" data-sentry-source-file="AppShell.tsx">
          <Container size={'xl'} data-sentry-element="Container" data-sentry-source-file="AppShell.tsx">
            <Group justify={isMobile ? 'center' : 'right'} gap={'xs'} mb={isMobile ? theme.spacing.lg : undefined} data-sentry-element="Group" data-sentry-source-file="AppShell.tsx">
              <ConnectWallet data-sentry-element="ConnectWallet" data-sentry-source-file="AppShell.tsx" />
            </Group>
            <MaintenanceBanner data-sentry-element="MaintenanceBanner" data-sentry-source-file="AppShell.tsx" />
            {children}
          </Container>
        </MantineShell.Main>
        <MantineShell.Footer data-sentry-element="unknown" data-sentry-source-file="AppShell.tsx">
          <Footer data-sentry-element="Footer" data-sentry-source-file="AppShell.tsx" />
        </MantineShell.Footer>
      </MantineShell>
    </>;
};