import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { isWalletSetupOpenAtom } from './atom';

export function useIsWalletSetupOpen(): {
  isOpen: boolean;
  open: () => void;
  close: () => void;
} {
  const [{ isOpen }, setIsOpen] = useRecoilState(isWalletSetupOpenAtom);

  const open = useCallback(() => {
    setIsOpen({ isOpen: true });
  }, [setIsOpen]);

  const close = useCallback(() => {
    setIsOpen({ isOpen: false });
  }, [setIsOpen]);

  return {
    isOpen,
    open,
    close,
  };
}
