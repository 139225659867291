import { useClipboard as useMantineClipboard } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { useTranslation } from 'next-i18next';

interface Clipboard {
  copy: (valueToCopy: string | undefined) => void;
  reset: () => void;
  error: Error | null;
  copied: boolean;
}

export function useClipboard(): Clipboard {
  const { t } = useTranslation();
  const {
    reset,
    error,
    copied,
    copy: mantineCopy,
  } = useMantineClipboard({ timeout: 500 });

  const copy = (valueToCopy: string | undefined): void => {
    mantineCopy(valueToCopy);
    showNotification({
      title: t('notifications.copiedToClipboard.title'),
      message: valueToCopy,
      color: 'grape',
    });
  };

  return { copy, reset, error, copied };
}
