import { sendGAEvent } from '@next/third-parties/google';
import { useCallback } from 'react';

type UseGaEventsResult = {
  sendButtonClickedEvent: (gaName?: string) => void;
  sendUrlOpenedEvent: (url?: string) => void;
};

export function useGaEvents(): UseGaEventsResult {
  const sendButtonClickedEvent = useCallback((gaName?: string) => {
    if (gaName) {
      sendGAEvent({ event: 'button_clicked', value: gaName });
    }
  }, []);

  const sendUrlOpenedEvent = useCallback((url?: string) => {
    if (url) {
      sendGAEvent({ event: 'url_opened', value: url });
    }
  }, []);

  return {
    sendButtonClickedEvent,
    sendUrlOpenedEvent,
  };
}
