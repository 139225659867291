import type { InjectedAccountWithMeta } from '@polkadot/extension-inject/types';
import { atom } from 'recoil';
import { createLocalStorageEffect } from 'recoil/effects';

export const shouldInitSubstrateExtensionsAtom = atom({
  key: 'shouldInitSubstrateExtensions',
  default: false,
  effects: [createLocalStorageEffect('should-init-substrate-extensions')],
});

export const substrateSelectedExtensionAtom = atom<string | undefined>({
  key: 'substrateExtension',
  default: undefined,
  effects: [
    createLocalStorageEffect<string | undefined>(
      'selected-substrate-extension',
    ),
  ],
});

export const substrateExtensionsAtom = atom<string[]>({
  key: 'substrateExtensions',
  default: [],
});

export const substrateAddressAtom = atom<string | undefined>({
  key: 'substrateAddress',
  default: undefined,
  effects: [
    createLocalStorageEffect<string | undefined>('selected-substrate-address'),
  ],
});

export const substrateAccountsAtom = atom<InjectedAccountWithMeta[]>({
  key: 'substrateAccounts',
  default: [],
});
