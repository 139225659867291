import { useFeatureByChain } from 'hooks/flags/useFeatureByChain';
import { useValidationAddresses } from 'hooks/flags/useValidationAddresses';
import { useAddress } from 'hooks/useAddress';
import { useIsMounted } from 'hooks/useIsMounted';

export function useFeatureDisableByChainOrWhitelist(feature: string): boolean {
  const address = useAddress();
  const isMounted = useIsMounted();
  const isDisabled = !!useFeatureByChain(feature);
  const addressValidation = useValidationAddresses();

  if (
    isMounted && // necessary to make sure address is already loaded
    addressValidation.length &&
    addressValidation.includes(address?.toLowerCase() as string)
  ) {
    return false;
  }

  return isDisabled;
}
