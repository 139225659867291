import { ActionIcon, Combobox, Group, InputBase, Modal, NumberInput, Switch, Text, useCombobox } from '@mantine/core';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useMemo, useState } from 'react';
import { HiOutlineWrenchScrewdriver } from 'react-icons/hi2';
import { useUserSettings } from 'recoil/userSettings';
import { SMALL_BALANCE_FILTERING_VALUE } from 'recoil/userSettings/atoms';
import { FiatCurrencies } from 'types/fiatCurrencies';
import { getFiatImage } from 'utils/images';
export function UserSettingsModal() {
  const {
    t
  } = useTranslation();
  const [opened, setOpened] = useState(false);
  const {
    areBalancesVisible,
    fiatCurrency,
    smallBalanceFilteringValue,
    hideBalances,
    showBalances,
    setFiatCurrency,
    setBalanceFilteringValue
  } = useUserSettings();
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption()
  });
  const data = useMemo(() => Object.values(FiatCurrencies).map(value => ({
    value,
    image: getFiatImage(value),
    label: value
  })), []);
  const options = useMemo(() => data.map(({
    value,
    image,
    label
  }) => <Combobox.Option value={value} key={value} style={{
    paddingTop: 0,
    paddingBottom: 0
  }}>
          <Group wrap={'nowrap'}>
            <Image width={30} src={image} alt={label} />
            <Text size={'sm'} tt={'uppercase'}>
              {label}
            </Text>
          </Group>
        </Combobox.Option>), [data]);
  return <>
      <Modal centered opened={opened} onClose={() => setOpened(false)} title={<Group gap={'xs'}>
            <HiOutlineWrenchScrewdriver size={20} />
            {t('settings')}
          </Group>} data-sentry-element="Modal" data-sentry-source-file="UserSettingsModal.tsx">
        <Combobox store={combobox} onOptionSubmit={value => {
        combobox.toggleDropdown();
        setFiatCurrency(value as FiatCurrencies);
      }} data-sentry-element="Combobox" data-sentry-source-file="UserSettingsModal.tsx">
          <Combobox.Target data-sentry-element="unknown" data-sentry-source-file="UserSettingsModal.tsx">
            <InputBase label={t('fiatCurrency')} styles={{
            input: {
              textTransform: 'uppercase'
            }
          }} component={'button'} type={'button'} pointer value={fiatCurrency} leftSection={<Image width={25} src={getFiatImage(fiatCurrency)} alt={fiatCurrency} />} rightSection={<Combobox.Chevron />} onClick={() => combobox.toggleDropdown()} rightSectionPointerEvents={'none'} data-sentry-element="InputBase" data-sentry-source-file="UserSettingsModal.tsx">
              {fiatCurrency}
            </InputBase>
          </Combobox.Target>
          <Combobox.Dropdown data-sentry-element="unknown" data-sentry-source-file="UserSettingsModal.tsx">
            <Combobox.Options data-sentry-element="unknown" data-sentry-source-file="UserSettingsModal.tsx">{options}</Combobox.Options>
          </Combobox.Dropdown>
        </Combobox>
        <Text pt={'lg'} size={'sm'} data-sentry-element="Text" data-sentry-source-file="UserSettingsModal.tsx">
          {t('hideBalances')}
        </Text>
        <Switch pt={2} label={t(areBalancesVisible ? 'no' : 'yes')} checked={!areBalancesVisible} onChange={() => areBalancesVisible ? hideBalances() : showBalances()} data-sentry-element="Switch" data-sentry-source-file="UserSettingsModal.tsx" />
        <NumberInput pt={'lg'} label={`${t('smallBalanceFilteringValue')} (${fiatCurrency.toUpperCase()})`} value={smallBalanceFilteringValue ?? SMALL_BALANCE_FILTERING_VALUE} onChange={setBalanceFilteringValue} data-sentry-element="NumberInput" data-sentry-source-file="UserSettingsModal.tsx" />
      </Modal>

      <ActionIcon onClick={() => setOpened(true)} title={t('icons.settings')} data-sentry-element="ActionIcon" data-sentry-source-file="UserSettingsModal.tsx">
        <HiOutlineWrenchScrewdriver size={20} data-sentry-element="HiOutlineWrenchScrewdriver" data-sentry-source-file="UserSettingsModal.tsx" />
      </ActionIcon>
    </>;
}