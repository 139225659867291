import { decodeAddress, encodeAddress } from '@polkadot/keyring';
import { hexToU8a, isHex } from '@polkadot/util';
import { KeypairType } from '@polkadot/util-crypto/types';
import { blockedAddresses } from 'utils/blockedAddresses';
import { isAddress } from 'viem';

export function isAddressValid(address: string, type?: KeypairType): boolean {
  if (type === 'ethereum') return isAddress(address);

  try {
    encodeAddress(isHex(address) ? hexToU8a(address) : decodeAddress(address));

    return true;
  } catch {
    return false;
  }
}

export function toSS58Format(address: string, ss58Format?: number): string {
  if (!ss58Format || !isAddressValid(address)) {
    return address;
  }

  return encodeAddress(address, ss58Format ? +ss58Format : undefined);
}

export function isBlockedAddress(account?: string): boolean {
  return !!account && blockedAddresses.includes(account.toLowerCase());
}
