import { httpBatchLink } from '@trpc/client';
import { createTRPCNext } from '@trpc/next';
import superjson from 'superjson';
import { isWindowDefined } from 'utils/window';
import type { AppRouter } from '../../../pages/api/trpc/[trpc]';

function getBaseUrl(): string {
  if (isWindowDefined())
    // browser should use relative path
    return '';

  // assume localhost
  return `http://localhost:${process.env.PORT ?? 3000}`;
}

export const trpc = createTRPCNext<AppRouter>({
  config() {
    return {
      links: [
        httpBatchLink({
          transformer: superjson,
          url: `${getBaseUrl()}/api/trpc`,
        }),
      ],
    };
  },
  transformer: superjson,
  /**
   * @link https://trpc.io/docs/ssr
   **/
  //   ssr: false,
});
