import { MrlToken } from 'services/mrl/tokens/Token';

const chainName = 'Polygon';

export const polygonWETH = MrlToken.create({
  symbol: 'WETH',
  decimals: 18,
  address: '0x11CD37bb86F65419713f30673A480EA33c826872',
  original: 'Ethereum',
  chain: chainName,
  key: 'weth',
  name: 'WETH',
});

export const polygonDai = MrlToken.create({
  symbol: 'DAI',
  decimals: 18,
  address: '0x732EB1747ecCFC431fF19bc359ffc83755B1918c',
  original: 'Ethereum',
  chain: chainName,
  key: 'dai',
  name: 'DAI',
});
