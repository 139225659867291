import { getPolkadotApi } from '@moonbeam-network/xcm-utils';
import { ApiPromise } from '@polkadot/api';
import { useMoonChain } from 'hooks/useMoonChainConfig';
import React, { useEffect, useState } from 'react';
export const ApiContext = React.createContext<ApiPromise | undefined>(undefined);
interface Props {
  children: React.ReactNode;
}
export function ApiProvider({
  children
}: Props) {
  const {
    ws
  } = useMoonChain();
  const [api, setApi] = useState<ApiPromise | undefined>();
  useEffect(() => {
    getPolkadotApi(ws).then(setApi);
  }, [ws]);
  return <ApiContext.Provider value={api} data-sentry-element="unknown" data-sentry-component="ApiProvider" data-sentry-source-file="ApiProvider.tsx">{children}</ApiContext.Provider>;
}