import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { isConnectWalletOpenAtom } from './atom';
import { WalletType } from './interfaces';

export function useIsConnectWalletOpen(): {
  isOpen: boolean;
  type: WalletType;
  open: (type: WalletType) => void;
  openEvm: () => void;
  toggleEvm: () => void;
  openSubstrate: () => void;
  close: () => void;
} {
  const [{ isOpen, type }, setIsOpen] = useRecoilState(isConnectWalletOpenAtom);

  const open = useCallback(
    (type: WalletType) => {
      setIsOpen({ isOpen: true, type });
    },
    [setIsOpen],
  );

  const openEvm = useCallback(() => {
    setIsOpen({ isOpen: true, type: WalletType.EVM });
  }, [setIsOpen]);

  const toggleEvm = useCallback(() => {
    setIsOpen(({ isOpen }) => ({ isOpen: !isOpen, type: WalletType.EVM }));
  }, [setIsOpen]);

  const openSubstrate = useCallback(() => {
    setIsOpen({ isOpen: true, type: WalletType.Substrate });
  }, [setIsOpen]);

  const close = useCallback(() => {
    setIsOpen({ isOpen: false, type: WalletType.EVM });
  }, [setIsOpen]);

  return {
    isOpen,
    type,
    open,
    openEvm,
    toggleEvm,
    openSubstrate,
    close,
  };
}
