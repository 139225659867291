import {
  getPrimaryShade,
  useComputedColorScheme,
  useMantineTheme,
} from '@mantine/core';

interface ThemeColorsResult {
  themeColors: Record<string, string>;
  primaryColor: string;
}

export function useThemeColors(): ThemeColorsResult {
  const colorScheme = useComputedColorScheme('dark');
  const theme = useMantineTheme();
  const {
    other: { themeColors: otherThemeColors },
  } = theme;

  const themeColors: Record<string, string> = {};

  for (const colorName in otherThemeColors) {
    if (colorName in otherThemeColors) {
      themeColors[colorName] = otherThemeColors[colorName][colorScheme];
    }
  }

  const primaryColor =
    theme.colors[theme.primaryColor][getPrimaryShade(theme, colorScheme)];

  return { themeColors, primaryColor };
}
