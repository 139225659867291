import { bool } from '@polkadot/types';
import { useApi } from 'hooks/useApi';
import { useApiCall } from 'hooks/useApiCall';

function transformToBool(v: bool): boolean {
  return v?.isTrue ?? false;
}

export function useMaintenanceMode(): boolean | undefined {
  const api = useApi();

  return useApiCall(
    api?.query.maintenanceMode.maintenanceMode,
    [],
    transformToBool,
  );
}
