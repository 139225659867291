import { ActionIcon } from '@mantine/core';
import { ConnectEvmWallet } from 'components/ConnectWallet/ConnectEvmWallet';
import { InputlikeText, Props as InputlikeTextProps } from 'components/InputlikeText';
import { Jazzicon } from 'components/Jazzicon';
import { useAddress } from 'hooks/useAddress';
import { useIsMounted } from 'hooks/useIsMounted';
import { useIsMobile, useIsTablet } from 'hooks/useMedia';
import { useTranslation } from 'next-i18next';
import { IoWallet } from 'react-icons/io5';
import { useIsConnectWalletOpen } from 'recoil/isConnectWalletOpen';
import { sliceEllipsis } from 'utils/text';
import { useAccount } from 'wagmi';
export type Props = Partial<InputlikeTextProps>;
export const EvmAccount = ({
  children,
  ...other
}: Props) => {
  const {
    t
  } = useTranslation();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isMounted = useIsMounted();
  const address = useAddress();
  const {
    isConnected
  } = useAccount();
  const {
    openEvm
  } = useIsConnectWalletOpen();
  const displayAddress = isMounted ? isMobile ? sliceEllipsis(address, 8, 4) : isTablet ? sliceEllipsis(address, 12, 12) : address : undefined;
  function renderConnectButton() {
    if (!isMounted || isConnected) {
      return null;
    }
    if (isMobile) {
      return <ActionIcon onClick={openEvm}>
          <IoWallet />
        </ActionIcon>;
    }
    return <ConnectEvmWallet mr={'xl'} data-sentry-element="ConnectEvmWallet" data-sentry-component="renderConnectButton" data-sentry-source-file="EvmAccount.tsx" />;
  }
  return <InputlikeText label={t('account')} placeholder={t('notConnected')} leftIcon={<Jazzicon address={isMounted ? address : undefined} size={24} />} rightIcon={renderConnectButton()} {...other} data-sentry-element="InputlikeText" data-sentry-component="EvmAccount" data-sentry-source-file="EvmAccount.tsx">
      {children || displayAddress}
    </InputlikeText>;
};