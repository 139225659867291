import { useIsDarkMode } from 'hooks/useIsDarkMode';
import { useMoonChainKeyParam } from 'hooks/useMoonChainParam';
import { darkThemeLogos, logos } from 'lib/themes/logos';

export function useThemeLogos(moonChainKey?: string): string {
  const currentKey = useMoonChainKeyParam();
  const isDarkMode = useIsDarkMode();

  const logosToUse = isDarkMode ? darkThemeLogos : logos;

  return moonChainKey ? logosToUse[moonChainKey] : logosToUse[currentKey];
}
