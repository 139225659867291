import { toDecimal } from '@moonbeam-network/xcm-utils';
import { RoundingMode } from 'big.js';
import { useMoonChain } from 'hooks/useMoonChainConfig';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

export function useToDecimal(
  value?: bigint | string,
  decimals = 12,
  maxDecimals = 6,
  noFormat = false,
  roundType?: RoundingMode,
): string | undefined {
  const { locale = 'en' } = useRouter();

  return useMemo(() => {
    if (value === undefined) {
      return undefined;
    }

    const result = toDecimal(value, decimals, maxDecimals, roundType);

    return noFormat
      ? result
      : (+result).toLocaleString(locale, {
          maximumFractionDigits: maxDecimals,
        });
  }, [value, decimals, maxDecimals, noFormat, roundType, locale]);
}

export function useMoonChainToDecimals(
  value?: bigint | string,
  maxDecimals = 6,
  noFormat = false,
  roundType?: RoundingMode,
): string | undefined {
  const config = useMoonChain();

  return useToDecimal(value, config.decimals, maxDecimals, noFormat, roundType);
}
