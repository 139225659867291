import { moonbeam } from '@moonbeam-network/xcm-config';
import { MrlMoonChain } from 'services/mrl/chains/classes';
import { fantomMrlChain } from 'services/mrl/chains/mainnet/fantom';
import {
  moonbeamGLMR,
  moonbeamPEAQ,
  moonbeamWFTM,
} from 'services/mrl/tokens/chainTokens/mainnet/moonbeamTokens';
import { mainnet } from 'viem/chains';

export const moonbeamMrlChain = new MrlMoonChain({
  key: moonbeam.key,
  name: moonbeam.name,
  chainId: moonbeam.id,
  explorer: 'https://moonbeam.moonscan.io',
  parachainId: moonbeam.parachainId,
  isAutomaticPossible: true,
  ss58Format: moonbeam.ss58Format,
  ws: moonbeam.ws,
  isTestChain: false,
  redeemChainId: moonbeam.id,
  redeemChainName: moonbeam.name,
  // TODO deprecate in favor of transferrableAssets
  destinations: [
    {
      chain: fantomMrlChain.name,
      tokens: [moonbeamGLMR, moonbeamWFTM, moonbeamPEAQ],
    },
    {
      chain: mainnet.name,
      tokens: [moonbeamPEAQ],
    },
  ],
  transferrableAssets: [
    {
      token: moonbeamGLMR,
      destination: fantomMrlChain.name,
    },
  ],
});
