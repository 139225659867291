import { http, WagmiProvider, createConfig } from 'wagmi';
import { coinbaseWallet, injected, walletConnect } from 'wagmi/connectors';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import moonbeamLogo from 'images/moonbeam-icon.webp';
import { peaqEvmAlphanetViemChain, peaqEvmViemChain } from 'providers/customChains';
import { getMoonChains } from 'utils/wagmi';
import { arbitrum, fantom, fantomTestnet, holesky, mainnet, moonbaseAlpha, moonbeam, moonriver, polygon } from 'viem/chains';
const NEXT_PUBLIC_WC_PROJECT_ID = process.env.NEXT_PUBLIC_PROJECT_ID || '';
const DAPP_NAME = 'Moonbeam apps';
export interface Props {
  children: React.ReactNode;
}
const config = createConfig({
  chains: [...getMoonChains(), holesky, fantomTestnet, polygon, fantom, arbitrum, mainnet, peaqEvmAlphanetViemChain, peaqEvmViemChain],
  connectors: [injected({
    target: 'metaMask'
  }), coinbaseWallet({
    appName: DAPP_NAME
  }), walletConnect({
    projectId: NEXT_PUBLIC_WC_PROJECT_ID,
    metadata: {
      name: DAPP_NAME,
      description: DAPP_NAME,
      url: 'https://apps.moonbeam.network',
      icons: [moonbeamLogo.src]
    }
  }),
  //@ts-expect-error not whithin the options but still recognized
  injected({
    target: 'novaWallet'
  })],
  ssr: true,
  transports: {
    [moonbeam.id]: http(),
    [moonriver.id]: http(),
    [moonbaseAlpha.id]: http(),
    [holesky.id]: http(),
    [fantomTestnet.id]: http(),
    [polygon.id]: http('https://polygon-bor-rpc.publicnode.com'),
    [fantom.id]: http('https://fantom-rpc.publicnode.com'),
    [arbitrum.id]: http('https://arbitrum-one.publicnode.com'),
    [mainnet.id]: http('https://ethereum-rpc.publicnode.com'),
    [peaqEvmAlphanetViemChain.id]: http(),
    [peaqEvmViemChain.id]: http()
  }
});
export const EvmWalletProvider = ({
  children
}: Props) => {
  const queryClient = new QueryClient();
  return <WagmiProvider config={config} data-sentry-element="WagmiProvider" data-sentry-component="EvmWalletProvider" data-sentry-source-file="EvmWalletProvider.tsx">
      <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="EvmWalletProvider.tsx">{children}</QueryClientProvider>
    </WagmiProvider>;
};