import type { Signer, UnsubscribePromise } from '@polkadot/api/types';
import type {
  web3AccountsSubscribe,
  web3Enable,
  web3EnablePromise,
  web3FromSource,
} from '@polkadot/extension-dapp';
import type { InjectedAccountWithMeta } from '@polkadot/extension-inject/types';
import { KeypairType } from '@polkadot/util-crypto/types';
import { delay } from 'utils/delay';
import { isWindowDefined } from 'utils/window';

export interface PolkadotExtensionFunctions {
  web3AccountsSubscribe: typeof web3AccountsSubscribe;
  web3Enable: typeof web3Enable;
  web3EnablePromise: typeof web3EnablePromise;
  web3FromSource: typeof web3FromSource;
}

export async function importPolkadot(): Promise<PolkadotExtensionFunctions> {
  const {
    web3AccountsSubscribe,
    web3Enable,
    web3FromSource,
    web3EnablePromise,
  } = await import('@polkadot/extension-dapp');

  return {
    web3AccountsSubscribe,
    web3Enable,
    web3FromSource,
    web3EnablePromise,
  };
}

export async function getExtensions(): Promise<string[]> {
  if (!isWindowDefined()) {
    return [];
  }

  const { web3Enable } = await importPolkadot();

  for (let i = 0; i < 10; i++) {
    const extensions = await web3Enable('Moonbeam Dapps');

    if (extensions.length) {
      return extensions.map((ext) => ext.name);
    }

    await delay(200);
  }

  return [];
}

export async function subscribeToAccounts(
  cb: (accounts: InjectedAccountWithMeta[]) => void,
): UnsubscribePromise {
  if (!isWindowDefined()) {
    return () => undefined;
  }

  const { web3AccountsSubscribe } = await importPolkadot();

  return web3AccountsSubscribe(cb);
}

export async function getSigner(
  extension: string | undefined,
): Promise<Signer | undefined> {
  if (!isWindowDefined() || !extension) {
    return;
  }

  const { web3FromSource, web3EnablePromise } = await importPolkadot();
  const extensions = await web3EnablePromise;

  if (
    !extensions?.length ||
    !extensions.some((ext) => ext.name === extension)
  ) {
    return;
  }

  const injector = await web3FromSource(extension);

  return injector?.signer;
}

export function createSourceFilter(source: string | undefined) {
  return (account: InjectedAccountWithMeta): boolean =>
    !source || account.meta.source === source;
}

export function createGenesisFilter(hashes: string[]) {
  return (account: InjectedAccountWithMeta): boolean =>
    !hashes.length ||
    !account.meta.genesisHash ||
    hashes.includes(account.meta.genesisHash);
}

export function createKeypairTypeFilter(
  type: KeypairType | undefined,
): (account: InjectedAccountWithMeta) => boolean {
  // By default only get Substrate accounts
  if (!type) return (account) => account.type !== 'ethereum';

  return (account) => account.type === type;
}
