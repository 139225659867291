import { Group, Stack } from '@mantine/core';
import cx from 'clsx';
import { SelectSubstrateAccount } from 'components/SelectSubstrateAccount';
import { WalletLogo } from 'components/WalletLogo';
import { useIsMobile } from 'hooks/useMedia';
import { Wallet, walletToSubstrateExtensionMap } from 'lib/wallets';
import { useEffect } from 'react';
import { useSubstrateExtension } from 'recoil/substrateExtension';
import { WalletButton } from './WalletButton';
import classes from './WalletButton.module.css';
export const SubstrateSection = () => {
  const {
    extensions,
    selectedExtension,
    setSelectedExtension,
    init
  } = useSubstrateExtension();
  const isMobile = useIsMobile();
  useEffect(() => init(), [init]);
  return <Stack p={'lg'} justify={'space-between'} mih={360} data-sentry-element="Stack" data-sentry-component="SubstrateSection" data-sentry-source-file="SubstrateSection.tsx">
      <Group mb={'md'} justify={isMobile ? 'center' : undefined} data-sentry-element="Group" data-sentry-source-file="SubstrateSection.tsx">
        {[Wallet.Polkadot, Wallet.SubWallet, Wallet.Talisman].map(wallet => {
        const extension = walletToSubstrateExtensionMap[wallet];
        const isAvailable = !!extension && extensions.includes(extension);
        if (isMobile && !isAvailable) return null;
        return <WalletButton key={wallet} className={cx({
          [classes.selected]: selectedExtension === extension
        })} disabled={!isAvailable} leftSection={<WalletLogo wallet={wallet} />} walletName={wallet} onClick={() => setSelectedExtension(extension)}>
              {wallet}
            </WalletButton>;
      })}
      </Group>
      <SelectSubstrateAccount data-sentry-element="SelectSubstrateAccount" data-sentry-source-file="SubstrateSection.tsx" />
    </Stack>;
};