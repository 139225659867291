import { MrlToken } from 'services/mrl/tokens/Token';

// these tokens are generated from Wormhole SDK
// const wh = new Wormhole(env, [EvmPlatform]);
// const moonbeamWhCHain = wh.getChain('Moonbeam');
// moonbeamWhCHain.config.tokenMap

export const moonbeamWETH = MrlToken.create({
  symbol: 'WETH',
  decimals: 18,
  address: '0xab3f0245B83feB11d15AAffeFD7AD465a59817eD',
  original: 'Ethereum',
  chain: 'Moonbeam',
  key: 'weth',
  name: 'WETH',
});

export const moonbeamUSDCeth = MrlToken.create({
  symbol: 'USDC',
  decimals: 6,
  address: '0x931715FEE2d06333043d11F658C8CE934aC61D0c',
  original: 'Ethereum',
  chain: 'Moonbeam',
  key: 'usdceth',
  name: 'USDCeth',
});

export const moonbeamWBTC = MrlToken.create({
  symbol: 'WBTC',
  decimals: 8,
  address: '0xE57eBd2d67B462E9926e04a8e33f01cD0D64346D',
  original: 'Ethereum',
  chain: 'Moonbeam',
  key: 'wbtc',
  name: 'WBTC',
});

export const moonbeamUSDT = MrlToken.create({
  symbol: 'USDT',
  decimals: 6,
  address: '0xc30E9cA94CF52f3Bf5692aaCF81353a27052c46f',
  original: 'Ethereum',
  chain: 'Moonbeam',
  key: 'usdt',
  name: 'USDT',
});

export const moonbeamDAI = MrlToken.create({
  symbol: 'DAI',
  decimals: 18,
  address: '0x06e605775296e851FF43b4dAa541Bb0984E9D6fD',
  original: 'Ethereum',
  chain: 'Moonbeam',
  key: 'dai',
  name: 'DAI',
});

export const moonbeamPEAQ = MrlToken.create({
  symbol: 'PEAQ',
  decimals: 18,
  address: '0xFffFFFFFEC4908b74688a01374f789B48E9a3eab',
  original: 'peaq',
  chain: 'Moonbeam',
  key: 'peaq',
  name: 'PEAQ',
});

export const moonbeamBUSD = MrlToken.create({
  symbol: 'BUSD',
  decimals: 18,
  address: '0xa2284e1F98E4d0B7Eb6a6b4f3C57f1b209C755F3',
  original: 'Ethereum',
  chain: 'Moonbeam',
  key: 'busd',
  name: 'BUSD',
});

export const moonbeamWMATIC = MrlToken.create({
  symbol: 'WMATIC',
  decimals: 18,
  address: '0x82DbDa803bb52434B1f4F41A6F0Acb1242A7dFa3',
  original: 'Polygon',
  chain: 'Moonbeam',
  key: 'wmatic',
  name: 'WMATIC',
});

export const moonbeamUSDCpolygon = MrlToken.create({
  symbol: 'USDC',
  decimals: 6,
  address: '0x4415BfBDee669446550d55c749007EF60B520FC8',
  original: 'Polygon',
  chain: 'Moonbeam',
  key: 'usdcpolygon',
  name: 'USDCpolygon',
});

export const moonbeamWBNB = MrlToken.create({
  symbol: 'WBNB',
  decimals: 18,
  address: '0xE3b841C3f96e647E6dc01b468d6D0AD3562a9eeb',
  original: 'Bsc',
  chain: 'Moonbeam',
  key: 'wbnb',
  name: 'WBNB',
});

export const moonbeamUSDCbnb = MrlToken.create({
  symbol: 'USDC',
  decimals: 18,
  address: '0x7f433E22366E03a3758CE22cCf82887d828078f8',
  original: 'Bsc',
  chain: 'Moonbeam',
  key: 'usdcbnb',
  name: 'USDCbnb',
});

export const moonbeamWAVAX = MrlToken.create({
  symbol: 'WAVAX',
  decimals: 18,
  address: '0xd4937A95BeC789CC1AE1640714C61c160279B22F',
  original: 'Avalanche',
  chain: 'Moonbeam',
  key: 'wavax',
  name: 'WAVAX',
});

export const moonbeamUSDCavax = MrlToken.create({
  symbol: 'USDC',
  decimals: 6,
  address: '0xd4918c40cA9f02d42Cb53d06587aF42017Bc345D',
  original: 'Avalanche',
  chain: 'Moonbeam',
  key: 'usdcavax',
  name: 'USDCavax',
});

export const moonbeamWFTM = MrlToken.create({
  symbol: 'WFTM',
  decimals: 18,
  address: '0x609AedD990bf45926bca9E4eE988b4Fb98587D3A',
  original: 'Fantom',
  chain: 'Moonbeam',
  key: 'wftm',
  name: 'Wrapped FTM',
});

export const moonbeamCELO = MrlToken.create({
  symbol: 'CELO',
  decimals: 18,
  address: '0xc1a792041985F65c17Eb65E66E254DC879CF380b',
  original: 'Celo',
  chain: 'Moonbeam',
  key: 'celo',
  name: 'CELO',
});

export const moonbeamGLMR = MrlToken.create({
  symbol: 'GLMR',
  decimals: 18,
  isNative: true,
  wrappedKey: 'WGLMR',
  chain: 'Moonbeam',
  key: 'glmr',
  name: 'Glimmer',
});

export const moonbeamWGLMR = MrlToken.create({
  symbol: 'WGLMR',
  decimals: 18,
  address: '0xAcc15dC74880C9944775448304B263D191c6077F',
  chain: 'Moonbeam',
  key: 'wglmr',
  name: 'WGLMR',
});

export const moonbeamWSOL = MrlToken.create({
  symbol: 'WSOL',
  decimals: 9,
  address: '0x99Fec54a5Ad36D50A4Bba3a41CAB983a5BB86A7d',
  original: 'Solana',
  chain: 'Moonbeam',
  key: 'wsol',
  name: 'WSOL',
});

export const moonbeamUSDCsol = MrlToken.create({
  symbol: 'USDC',
  decimals: 6,
  address: '0x098d6eE48341D6a0a0A72dE5baaF80A10E0F6082',
  original: 'Solana',
  chain: 'Moonbeam',
  key: 'usdcsol',
  name: 'USDCsol',
});

export const moonbeamSUI = MrlToken.create({
  symbol: 'SUI',
  decimals: 9,
  address: '0x484eCCE6775143D3335Ed2C7bCB22151C53B9F49',
  original: 'Sui',
  chain: 'Moonbeam',
  key: 'sui',
  name: 'SUI',
});

export const moonbeamAPT = MrlToken.create({
  symbol: 'APT',
  decimals: 8,
  address: '0x25331575641d35D9765e1934acC8F0991c58e904',
  original: 'Aptos',
  chain: 'Moonbeam',
  key: 'apt',
  name: 'APT',
});

export const moonbeamWETHarbitrum = MrlToken.create({
  symbol: 'WETH',
  decimals: 18,
  address: '0x18872b45c603eD2EbC508b9C5514a85c2e2791FB',
  original: 'Arbitrum',
  chain: 'Moonbeam',
  key: 'wetharbitrum',
  name: 'WETHarbitrum',
});

export const moonbeamWETHoptimism = MrlToken.create({
  symbol: 'WETH',
  decimals: 18,
  address: '0xd4870F7F5AD8Ae5139E1a5D8AD4ac55204aE4490',
  original: 'Optimism',
  chain: 'Moonbeam',
  key: 'wethoptimism',
  name: 'WETHoptimism',
});

export const moonbeamUSDCoptimism = MrlToken.create({
  symbol: 'USDC',
  decimals: 6,
  address: '0x7143e8EA96e158381057a58AfdDF44601c7e532C',
  original: 'Optimism',
  chain: 'Moonbeam',
  key: 'usdcoptimism',
  name: 'USDCoptimism',
});

export const moonbeamWETHbase = MrlToken.create({
  symbol: 'WETH',
  decimals: 18,
  address: '0x6C6f83366A42fcA4D30a2D3f1914284de995Ac3a',
  original: 'Base',
  chain: 'Moonbeam',
  key: 'wethbase',
  name: 'WETHbase',
});

export const moonbeamUSDCbase = MrlToken.create({
  symbol: 'USDC',
  decimals: 6,
  address: '0xE6d02a875CcC153c076fe418f33De3A5C420f505',
  original: 'Base',
  chain: 'Moonbeam',
  key: 'usdcbase',
  name: 'USDCbase',
});

export const moonbeamtBTC = MrlToken.create({
  symbol: 'tBTC',
  decimals: 18,
  address: '0xeCd65E4B89495Ae63b4f11cA872a23680A7c419c',
  original: 'Ethereum',
  chain: 'Moonbeam',
  key: 'tbtc',
  name: 'tBTC',
});

export const moonbeamWKLAY = MrlToken.create({
  symbol: 'WKLAY',
  decimals: 18,
  address: '0xf0a9476E4712123A807859f9Fd25fe98213379BD',
  original: 'Klaytn',
  chain: 'Moonbeam',
  key: 'wklay',
  name: 'WKLAY',
});
