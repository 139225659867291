import { Box, Group, Text, TextProps } from '@mantine/core';
import { ReactNode } from 'react';
import classes from './InputlikeText.module.css';
export interface Props extends TextProps {
  children?: string;
  placeholder?: string;
  label?: string;
  width?: number;
  rightIcon?: ReactNode;
  leftIcon?: ReactNode;
}
export const InputlikeText = ({
  className,
  children,
  label,
  placeholder,
  width,
  rightIcon,
  leftIcon,
  ...others
}: Props) => {
  function renderRightIcon() {
    if (!rightIcon) {
      return null;
    }
    if (typeof rightIcon === 'string') {
      return <Text className={classes.rightText}>{rightIcon}</Text>;
    }
    return rightIcon;
  }
  return <Box className={className} w={width} {...others} data-sentry-element="Box" data-sentry-component="InputlikeText" data-sentry-source-file="InputlikeText.tsx">
      {label ? <Text component={'label'} size={'sm'}>
          {label}
        </Text> : null}
      <Group className={classes.group} w={width} justify={'space-between'} px={'sm'} data-sentry-element="Group" data-sentry-source-file="InputlikeText.tsx">
        {children ? <Group gap={'xs'}>
            {leftIcon}
            <Text lh={2} className={classes.text}>
              {children}
            </Text>
          </Group> : <Text lh={2} className={classes.placeholder}>
            {placeholder}
          </Text>}
        {renderRightIcon()}
      </Group>
    </Box>;
};