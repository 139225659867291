import {
  acala,
  alphanetAssetHub,
  alphanetRelay,
  astar,
  bifrostKusama,
  bifrostPolkadot,
  calamari,
  centrifuge,
  crustShadow,
  darwinia,
  darwiniaCrab,
  hydration,
  integritee,
  interlay,
  karura,
  khala,
  kintsugi,
  kusama,
  kusamaAssetHub,
  mangataKusama,
  mantaParachain,
  moonbaseAlpha,
  moonbeam,
  moonriver,
  neuroweb,
  nodle,
  originTrailAlphanet,
  peaqChain,
  peaqEvm,
  pendulum,
  pendulumAlphanet,
  phala,
  picasso,
  picassoAlphanet,
  polkadot,
  polkadotAssetHub,
  robonomics,
  shiden,
  subsocial,
  tinkernet,
  turing,
  turingAlphanet,
  uniqueAlpha,
  zeitgeist,
} from '@moonbeam-network/xcm-config';
import { localDevConfig } from 'configs/chains';
import { StaticImageData } from 'next/image';

import acaLogo from 'images/logos/aca.png';
import ampeLogo from 'images/logos/ampe.png';
import arbLogo from 'images/logos/arbitrum-arb-logo.png';
import assetHub from 'images/logos/assetHub.svg';
import astrLogo from 'images/logos/astr.png';
import bncLogo from 'images/logos/bnc.png';
import cfgReversedLogo from 'images/logos/cfg-reversed.png';
import cfgLogo from 'images/logos/cfg.png';
import crabLogo from 'images/logos/crab.png';
import crustShadowLogo from 'images/logos/crustShadow.png';
import darwiniaLogo from 'images/logos/darwinia.png';
import dotLogo from 'images/logos/dot.png';
import ethLogo from 'images/logos/ethereum-eth-logo.png';
import fantomLogo from 'images/logos/fantom-ftm-logo.png';
import hdxLogo from 'images/logos/hydration.png';
import intrReversedLogo from 'images/logos/intr-reversed.svg';
import intrLogo from 'images/logos/intr.svg';
import karLogo from 'images/logos/kar.png';
import khalaLogo from 'images/logos/khala.svg';
import kintReversedLogo from 'images/logos/kint-reversed.svg';
import kintLogo from 'images/logos/kint.png';
import kmaLogo from 'images/logos/kma.png';
import ksmLogo from 'images/logos/ksm.png';
import mangataLogo from 'images/logos/mangata.png';
import mantaLogo from 'images/logos/manta.png';
import neuroLogo from 'images/logos/neuro.png';
import nodlLogo from 'images/logos/nodl.png';
import originTrailLogo from 'images/logos/originTrail.png';
import peaq from 'images/logos/peaq.png';
import penLogo from 'images/logos/pen.png';
import phaLogo from 'images/logos/pha.png';
import picaLogo from 'images/logos/pica.png';
import sdnLogo from 'images/logos/sdn.png';
import subLogo from 'images/logos/sub.png';
import teerLogo from 'images/logos/teer.png';
import tnkrLogo from 'images/logos/tnkr.svg';
import turLogo from 'images/logos/tur.png';
import unqLogo from 'images/logos/unq.svg';
import wormholeLogo from 'images/logos/wormhole-logo.png';
import robonomicsLogo from 'images/logos/xrt.svg';
import zeitgeistLogo from 'images/logos/zeitgeist.png';
import moonbaseLogo from 'images/moonbase-icon.webp';
import moonbeamLogo from 'images/moonbeam-icon-new.svg';
import moonriverLogo from 'images/moonriver-icon-new.svg';
import {
  arbitrumMrlChain,
  ethereumMainnetChain,
  fantomMrlChain,
} from 'services/mrl/chains/mainnet';
import { fantomTestnetMrlChain } from 'services/mrl/chains/testnet';

export const logos: Partial<Record<string, StaticImageData>> = {
  [acala.key]: acaLogo,
  [alphanetAssetHub.key]: assetHub,
  [alphanetRelay.key]: ksmLogo,
  [astar.key]: astrLogo,
  [bifrostKusama.key]: bncLogo,
  [bifrostPolkadot.key]: bncLogo,
  [calamari.key]: kmaLogo,
  [centrifuge.key]: cfgLogo,
  [crustShadow.key]: crustShadowLogo,
  [darwinia.key]: darwiniaLogo,
  [darwiniaCrab.key]: crabLogo,
  [hydration.key]: hdxLogo,
  [integritee.key]: teerLogo,
  [interlay.key]: intrLogo,
  [karura.key]: karLogo,
  [khala.key]: khalaLogo,
  [kintsugi.key]: kintLogo,
  [kusama.key]: ksmLogo,
  [kusamaAssetHub.key]: assetHub,
  [mangataKusama.key]: mangataLogo,
  [mantaParachain.key]: mantaLogo,
  [moonbaseAlpha.key]: moonbaseLogo,
  [moonbeam.key]: moonbeamLogo,
  [moonriver.key]: moonriverLogo,
  [neuroweb.key]: neuroLogo,
  [nodle.key]: nodlLogo,
  [originTrailAlphanet.key]: originTrailLogo,
  [pendulum.key]: penLogo,
  [pendulumAlphanet.key]: ampeLogo,
  [phala.key]: phaLogo,
  [picasso.key]: picaLogo,
  [picassoAlphanet.key]: picaLogo,
  [polkadot.key]: dotLogo,
  [polkadotAssetHub.key]: assetHub,
  [robonomics.key]: robonomicsLogo,
  [shiden.key]: sdnLogo,
  [subsocial.key]: subLogo,
  [tinkernet.key]: tnkrLogo,
  [turing.key]: turLogo,
  [turingAlphanet.key]: turLogo,
  [uniqueAlpha.key]: unqLogo,
  [zeitgeist.key]: zeitgeistLogo,
  [localDevConfig.key]: moonbaseLogo,
  [fantomTestnetMrlChain.key]: fantomLogo,
  [fantomMrlChain.key]: fantomLogo,
  [ethereumMainnetChain.key]: ethLogo,
  [arbitrumMrlChain.key]: arbLogo,
  ['wormhole']: wormholeLogo,
  [peaqEvm.key]: peaq,
  [peaqChain.key]: peaq,
};

export const reversedLogos: Partial<Record<string, StaticImageData>> = {
  [centrifuge.key]: cfgReversedLogo,
  [interlay.key]: intrReversedLogo,
  [kintsugi.key]: kintReversedLogo,
};
