import { AtomEffect } from 'recoil';
import { jsonParseWithBigInt, jsonStringifyWithBigInt } from 'utils/json';
import { isWindowDefined } from 'utils/window';

export function createLocalStorageEffect<State>(
  key: string,
): AtomEffect<State> {
  return ({ setSelf, onSet }) => {
    if (!isWindowDefined()) {
      return;
    }

    const savedValue = localStorage.getItem(key);

    if (
      savedValue !== null &&
      savedValue !== undefined &&
      savedValue !== 'undefined'
    ) {
      setSelf(jsonParseWithBigInt(savedValue));
    }

    onSet((newValue, _, isReset) => {
      isReset || !newValue
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, jsonStringifyWithBigInt(newValue));
    });
  };
}
