import {
  dev,
  hydrationAlphanet,
  moonbaseAlpha,
} from '@moonbeam-network/xcm-config';
import { Ecosystem, MrlChainType } from 'services/mrl/chains/classes/BaseChain';
import { MrlParachain } from 'services/mrl/chains/classes/MrlParachain';
import { mrlTestnetFtmWH } from 'services/mrl/tokens/chainTokens/testnet/parachainTestnetTokens';
import { fantomTestnet } from 'viem/chains';

export const hydrationAlphanetMrlChain = new MrlParachain({
  key: hydrationAlphanet.key,
  name: hydrationAlphanet.name,
  type: MrlChainType.Parachain,
  parachainId: hydrationAlphanet.parachainId,
  genesisHash: hydrationAlphanet.genesisHash,
  isTestChain: hydrationAlphanet.isTestChain,
  isAutomaticPossible: false,
  ss58Format: hydrationAlphanet.ss58Format,
  ws: hydrationAlphanet.ws,
  explorer: `https://polkadot.js.org/apps/?rpc=${encodeURIComponent(Array.isArray(hydrationAlphanet.ws) ? hydrationAlphanet.ws[0] : hydrationAlphanet.ws)}#/explorer/query`,
  ecosystem: Ecosystem.Dotsama,
  redeemChainId: moonbaseAlpha.id,
  redeemChainName: moonbaseAlpha.name,
  destinations: [
    {
      chain: fantomTestnet.name,
      // tokens: [usdcwh, dev, ftmwh], // need to check
      tokens: [mrlTestnetFtmWH],
      fees: [
        // TODO relate this to the fees used in the polkadot extrinsic builder (cross-chain fee + buy exectution fee)
        { asset: dev, min: 0.1 },
      ],
    },
  ],
  transferrableAssets: [
    {
      token: mrlTestnetFtmWH,
      destination: fantomTestnet.name,
      fees: [
        // TODO relate this to the fees used in the polkadot extrinsic builder (cross-chain fee + buy exectution fee)
        { asset: dev, min: 0.1 },
      ],
      destinationFee: 0.0001,
    },
  ],
});
