import { Alert, Text } from '@mantine/core';
import { useMaintenanceModeDescriptionFlag } from 'hooks/flags/useIsMaintenanceModFlag';
import { useMaintenanceMode } from 'hooks/queries/useMaintenanceMode';
import { useMoonChain } from 'hooks/useMoonChainConfig';
import { useTranslation } from 'next-i18next';
import { IoAlertCircleOutline } from 'react-icons/io5';
export const MaintenanceBanner = () => {
  const {
    t
  } = useTranslation();
  const isMaintenance = useMaintenanceMode();
  const {
    name
  } = useMoonChain();
  const flag = useMaintenanceModeDescriptionFlag();
  const text = flag || t('maintenanceModeDefaultMessage', {
    chain: name
  });
  if (!isMaintenance) return null;
  return <>
      <Alert my={'lg'} p={'xl'} icon={<IoAlertCircleOutline size={'2rem'} />} title={t('importantInfo')} color={'red'} data-sentry-element="Alert" data-sentry-source-file="MaintenanceBanner.tsx">
        <Text size={'xl'} data-sentry-element="Text" data-sentry-source-file="MaintenanceBanner.tsx">{text}</Text>
      </Alert>
    </>;
};