import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { offsiteModalAtom } from './atom';

export function useOffsiteModal(): {
  isOpen: boolean;
  url: string;
  open: (url: string) => void;
  close: () => void;
} {
  const [{ isOpen, url }, setOffsiteModal] = useRecoilState(offsiteModalAtom);

  const open = useCallback(
    (url: string) => {
      setOffsiteModal({ isOpen: true, url });
    },
    [setOffsiteModal],
  );

  const close = useCallback(() => {
    setOffsiteModal({ isOpen: false, url: '' });
  }, [setOffsiteModal]);

  return {
    isOpen,
    url,
    open,
    close,
  };
}
