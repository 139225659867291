import { glmr, hydration, moonbeam } from '@moonbeam-network/xcm-config';
import { MrlParachain } from 'services/mrl/chains/classes';
import { Ecosystem, MrlChainType } from 'services/mrl/chains/classes/BaseChain';
import {
  mrlDAI,
  mrlUSDCWH,
  mrlUSDT,
  mrlWBTC,
  mrlWETH,
} from 'services/mrl/tokens/chainTokens/mainnet/parachainTokens';
import { mainnet } from 'viem/chains';

export const hydrationMrlChain = new MrlParachain({
  key: hydration.key,
  name: hydration.name,
  parachainId: hydration.parachainId,
  genesisHash: hydration.genesisHash,
  isTestChain: hydration.isTestChain,
  isAutomaticPossible: false,
  redeemChainId: moonbeam.id,
  redeemChainName: moonbeam.name,
  ws: hydration.ws,
  type: MrlChainType.Parachain,
  ss58Format: hydration.ss58Format,
  explorer: `https://hydration.subscan.io/block`,
  ecosystem: Ecosystem.Dotsama,
  // TODO deprecate in favor of transferrableAssets
  destinations: [
    {
      chain: mainnet.name,
      tokens: [mrlWETH, mrlDAI, mrlUSDCWH, mrlUSDT, mrlWBTC],
      fees: [
        // TODO relate this to the fees used in the polkadot extrinsic builder (cross-chain fee + buy exectution fee)
        { asset: glmr, min: 0.1 }, // TODO: check this
        // { asset: ftmwh, min: 0.12 }, // NOT SURE WHAT ELSE
      ],
    },
  ],
  transferrableAssets: [
    {
      token: mrlWETH,
      destination: mainnet.name,
      fees: [
        { asset: glmr, min: 0.1 }, // TODO: check this
      ],
    },
  ],
});
