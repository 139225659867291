import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { isTermsModalOpenAtom } from './atom';

export function useIsTermsModalOpen(): {
  isOpen: boolean;
  open: () => void;
  close: () => void;
} {
  const [{ isOpen }, setIsOpen] = useRecoilState(isTermsModalOpenAtom);

  const open = useCallback(() => {
    setIsOpen({ isOpen: true });
  }, [setIsOpen]);

  const close = useCallback(() => {
    setIsOpen({ isOpen: false });
  }, [setIsOpen]);

  return {
    isOpen,
    open,
    close,
  };
}
