import { useTranslation } from 'next-i18next';
import { ActionButton } from 'components/ActionButton';
import { useIsMounted } from 'hooks/useIsMounted';
import { useMoonChainKeyParam } from 'hooks/useMoonChainParam';
import { useCallback, useEffect, useState } from 'react';
interface Props {
  partnerAddress: string;
}
export const CBSubscribeButton = ({
  partnerAddress
}: Props) => {
  const {
    t
  } = useTranslation();
  const isMounted = useIsMounted();
  const [isSubscribed, setIsSubscribed] = useState<boolean | undefined>(undefined);
  const moonChain = useMoonChainKeyParam();
  const [isLoading, setIsLoading] = useState(false);
  const subscribeButtonText = isSubscribed ? t('unsubscribe') : t('subscribe');
  const handleSubscribe = useCallback(() => {
    if (window.CBWSubscribe) {
      window.CBWSubscribe.toggleSubscription();
    }
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (isMounted && window.CBWSubscribe && partnerAddress) {
      window.CBWSubscribe.createSubscriptionUI({
        partnerAddress,
        partnerName: 'Moonbeam Foundation',
        modalTitle: t('subscribeToCBMessagingUI.title'),
        modalBody: t('subscribeToCBMessagingUI.body'),
        onSubscriptionChange: setIsSubscribed,
        onLoading: setIsLoading
      });
    }
  }, [t, isMounted, isSubscribed, setIsSubscribed, moonChain, partnerAddress]);
  return <ActionButton loading={isLoading || isSubscribed === undefined} onClick={handleSubscribe} data-sentry-element="ActionButton" data-sentry-component="CBSubscribeButton" data-sentry-source-file="CBSubscribeButton.tsx">
      {subscribeButtonText}
    </ActionButton>;
};