import { useFlags } from 'flagsmith/react';
import { useMoonChainKeyParam } from 'hooks/useMoonChainParam';
import { parseJson } from 'utils/json';

export function useMaintenanceModeDescriptionFlag(): string | undefined {
  const chain = useMoonChainKeyParam();
  const { maintenance_mode_description: flag } = useFlags([
    'maintenance_mode_description',
  ]);

  if (!flag.enabled) return undefined;

  const value = parseJson(flag.value);

  return value[chain];
}
