import { Wallet } from 'lib/wallets';

export enum Pages {
  select,
  evm,
  substrate,
}

export interface WalletInfo {
  name: Wallet;
  version?: string;
  isAvailable?: boolean;
  isConnected?: boolean;
  primary: string;
  secondary: string;
  link: {
    label: string;
    url: string;
  };
}
