import { MantineColorScheme } from '@mantine/core';
import { referendumStatusColors } from 'lib/themes/theme';
import { ReferendumStatus } from 'types/governance';

export function isDarkScheme(themeOrScheme: MantineColorScheme): boolean {
  return themeOrScheme === 'dark';
}

export function getReferendumStatusColor(status: ReferendumStatus): string {
  return referendumStatusColors[status];
}

export const trackIconsMap = new Map<string, string>([
  ['root', String.fromCodePoint(0x26a1)], // ⚡
  ['whitelisted_caller', String.fromCodePoint(0x1f511)], // 🔑
  ['general_admin', String.fromCodePoint(0x1f527)], // 🔧
  ['referendum_canceller', String.fromCodePoint(0x26d4)], // ⛔
  ['referendum_killer', String.fromCodePoint(0x274c)], // ❌
  ['fast_general_admin', String.fromCodePoint(0x1f680)], //  🚀
]);

export function getReferendaTracksIconOrDefault(trackName: string): string {
  return trackIconsMap.get(trackName) ?? String.fromCodePoint(0x2796);
}
