import { moonbaseAlpha } from '@moonbeam-network/xcm-config';
import { MrlMoonChain } from 'services/mrl/chains/classes/MrlMoonChain';
import {
  moonbaseAlphaGLMR,
  moonbaseAlphaWFTM,
} from 'services/mrl/tokens/chainTokens';
import { fantomTestnet } from 'viem/chains';

export const moonbaseAlphaMrlChain = new MrlMoonChain({
  key: moonbaseAlpha.key,
  name: moonbaseAlpha.name,
  chainId: moonbaseAlpha.id,
  explorer: 'https://moonbase.moonscan.io',
  parachainId: moonbaseAlpha.parachainId,
  isAutomaticPossible: true,
  ss58Format: moonbaseAlpha.ss58Format,
  ws: moonbaseAlpha.ws,
  isTestChain: true,
  redeemChainId: moonbaseAlpha.id,
  redeemChainName: moonbaseAlpha.name,
  destinations: [
    {
      chain: fantomTestnet.name,
      tokens: [moonbaseAlphaGLMR, moonbaseAlphaWFTM], // Used tokens from Wormhole. Balances are from Covalent
    },
  ],
  transferrableAssets: [
    {
      token: moonbaseAlphaGLMR,
      destination: fantomTestnet.name,
    },
    {
      token: moonbaseAlphaWFTM,
      destination: fantomTestnet.name,
    },
  ],
});
