import { Box, Group, Paper, Text, Title, Tooltip } from '@mantine/core';
import { MoonChainButton } from 'components/MoonChainButton';
import { moonbaseConfig, moonbeamConfig, moonriverConfig } from 'configs/chains';
import { useIsMobile } from 'hooks/useMedia';
import { useTranslation } from 'next-i18next';
import { IoInformationCircleOutline } from 'react-icons/io5';
import classes from './MoonChainSection.module.css';
export const MoonChainSection = () => {
  const {
    t
  } = useTranslation();
  const isMobile = useIsMobile();
  return <Paper px={'lg'} pb={'sm'} className={classes.chainPaper} data-sentry-element="Paper" data-sentry-component="MoonChainSection" data-sentry-source-file="MoonChainSection.tsx">
      <Title order={4} display={'inline-block'} data-sentry-element="Title" data-sentry-source-file="MoonChainSection.tsx">
        {t('networks')}
      </Title>
      <Tooltip label={<>
            <Text>{t('networkTooltip1')}</Text>
            <Text>{t('networkTooltip2')}</Text>
          </>} withArrow data-sentry-element="Tooltip" data-sentry-source-file="MoonChainSection.tsx">
        <Box ml={'xs'} display={'inline-block'} data-sentry-element="Box" data-sentry-source-file="MoonChainSection.tsx">
          <IoInformationCircleOutline data-sentry-element="IoInformationCircleOutline" data-sentry-source-file="MoonChainSection.tsx" />
        </Box>
      </Tooltip>
      <Group my={'md'} gap={'md'} justify={isMobile ? 'center' : undefined} data-sentry-element="Group" data-sentry-source-file="MoonChainSection.tsx">
        {[moonbeamConfig, moonriverConfig, moonbaseConfig].map(config => <MoonChainButton key={config.key} classNames={{
        root: classes.root
      }} chain={config} />)}
      </Group>
    </Paper>;
};