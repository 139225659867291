import { Anchor, Box, Tabs } from '@mantine/core';
import { polkadot } from '@moonbeam-network/xcm-config';
import { ChainLogo } from 'components/ChainLogo';
import { SubstrateSection } from 'components/ConnectWallet/sections/SubstrateSection';
import { useIsTablet } from 'hooks/useMedia';
import { useMoonChain } from 'hooks/useMoonChainConfig';
import { useTranslation } from 'next-i18next';
import { WalletType, useIsConnectWalletOpen } from 'recoil/isConnectWalletOpen';
import { useIsWalletSetupOpen } from 'recoil/isWalletSetupOpen';
import { EvmSection } from './sections/EvmSection';
import { MoonChainSection } from './sections/MoonChainSection';
export const ConnectContent = () => {
  const {
    t
  } = useTranslation();
  const chain = useMoonChain();
  const {
    type,
    open,
    close
  } = useIsConnectWalletOpen();
  const {
    open: openWalletSetup
  } = useIsWalletSetupOpen();
  const isTablet = useIsTablet();
  const onOpenWalletSetup = () => {
    close();
    openWalletSetup();
  };
  return <Box maw={480} data-sentry-element="Box" data-sentry-component="ConnectContent" data-sentry-source-file="ConnectContent.tsx">
      <MoonChainSection data-sentry-element="MoonChainSection" data-sentry-source-file="ConnectContent.tsx" />
      <Tabs value={type} onChange={v => open(v as WalletType)} variant={'outline'} keepMounted={false} data-sentry-element="Tabs" data-sentry-source-file="ConnectContent.tsx">
        <Tabs.List justify={'center'} data-sentry-element="unknown" data-sentry-source-file="ConnectContent.tsx">
          <Tabs.Tab value={WalletType.EVM} leftSection={<ChainLogo chain={chain.key} />} data-sentry-element="unknown" data-sentry-source-file="ConnectContent.tsx">
            {t('evmWallet')}
          </Tabs.Tab>
          <Tabs.Tab value={WalletType.Substrate} leftSection={<ChainLogo chain={polkadot.key} />} data-sentry-element="unknown" data-sentry-source-file="ConnectContent.tsx">
            {t('substrateWallet')}
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value={WalletType.EVM} display={'flex'} data-sentry-element="unknown" data-sentry-source-file="ConnectContent.tsx">
          <EvmSection data-sentry-element="EvmSection" data-sentry-source-file="ConnectContent.tsx" />
        </Tabs.Panel>
        <Tabs.Panel value={WalletType.Substrate} display={'flex'} data-sentry-element="unknown" data-sentry-source-file="ConnectContent.tsx">
          <SubstrateSection data-sentry-element="SubstrateSection" data-sentry-source-file="ConnectContent.tsx" />
        </Tabs.Panel>
      </Tabs>
      {!isTablet && <Box ta={'center'} pb={'md'}>
          <Anchor style={theme => ({
        color: theme.other.colors.action
      })} onClick={onOpenWalletSetup}>
            {t('walletSetup.setupWallet')}
          </Anchor>
        </Box>}
    </Box>;
};