import {
  glmr,
  moonbeam,
  peaq,
  peaqChain,
  peaqEvm,
} from '@moonbeam-network/xcm-config';
import { peaqEvmViemChain } from 'providers/customChains';
import { MrlEvmParachain, MrlParachain } from 'services/mrl/chains/classes';
import { Ecosystem, MrlChainType } from 'services/mrl/chains/classes/BaseChain';
import {
  mrlDAI,
  mrlPEAQ,
  mrlUSDCWH,
  mrlUSDT,
  mrlWBTC,
  mrlWETH,
} from 'services/mrl/tokens/chainTokens/mainnet/parachainTokens';
import { Address } from 'viem';
import { mainnet } from 'viem/chains';

export const peaqChainMrlChain = new MrlParachain({
  key: peaqChain.key,
  name: peaqChain.name,
  type: MrlChainType.Parachain,
  parachainId: peaqChain.parachainId,
  genesisHash: peaqChain.genesisHash,
  isTestChain: peaqChain.isTestChain,
  isAutomaticPossible: false,
  ss58Format: peaqChain.ss58Format,
  ws: peaqChain.ws,
  explorer: `https://polkadot.js.org/apps/?rpc=${encodeURIComponent(
    Array.isArray(peaqChain.ws) ? peaqChain.ws[0] : peaqChain.ws,
  )}#/explorer/query`,
  ecosystem: Ecosystem.Dotsama,
  redeemChainId: moonbeam.id,
  redeemChainName: moonbeam.name,
  destinations: [
    {
      chain: mainnet.name,
      tokens: [mrlWETH, mrlDAI, mrlUSDCWH, mrlUSDT, mrlWBTC, mrlPEAQ],
      fees: [{ asset: glmr, min: 0.1 }],
    },
  ],
  // FIXME: not needed yet, but eventually we'll migrate all the fees calculation to this prop
  // and make the `destinations` prop obsolete
  transferrableAssets: [
    {
      token: mrlPEAQ,
      destination: mainnet.name,
      fees: [{ asset: peaq, min: 0.15 }],
      // TODO technically not correct, it's not destination fee but fees in Moonbeam,
      // but we don't yet have a way to separate fee assets in the field `fees`
      // fix this with the new interface we use to migrate to the sdk
      destinationFee: 0.15,
    },
  ],
});

// TODO: for sure needs refactoring
const peaqEvmUSDC = {
  ...mrlUSDCWH,
  address: peaqEvm.assetsData.get(mrlUSDCWH.asset.key)?.id as Address,
};

const peaqEvmWETH = {
  ...mrlWETH,
  address: peaqEvm.assetsData.get(mrlWETH.asset.key)?.id as Address,
};

const peaqEvmWBTC = {
  ...mrlWBTC,
  address: peaqEvm.assetsData.get(mrlWBTC.asset.key)?.id as Address,
};

const peaqEvmDAI = {
  ...mrlDAI,
  address: peaqEvm.assetsData.get(mrlDAI.asset.key)?.id as Address,
};

const peaqEvmUSDT = {
  ...mrlUSDT,
  address: peaqEvm.assetsData.get(mrlUSDT.asset.key)?.id as Address,
};

const glmrAddressOnPeaq =
  '0xFfFfFffF000000000000000000000000000003e8' as Address;

export const peaqEvmMrlChain = new MrlEvmParachain({
  key: peaqEvm.key,
  name: peaqEvm.name,
  parachainId: peaqEvm.parachainId,
  genesisHash: peaqEvm.genesisHash,
  chainId: peaqEvm.id,
  isTestChain: peaqEvm.isTestChain,
  isAutomaticPossible: false,
  ss58Format: peaqEvm.ss58Format,
  ws: peaqEvm.ws,
  explorer: `https://peaq.subscan.io`,
  ecosystem: Ecosystem.Dotsama,
  redeemChainId: moonbeam.id,
  redeemChainName: moonbeam.name,
  viemChain: peaqEvmViemChain,
  moonTokenAddress: glmrAddressOnPeaq, // ? Not sure if good idea to have it here, It's for DEV/GLMR on the Evm Parachain
  destinations: [
    {
      chain: mainnet.name,
      tokens: [peaqEvmUSDC, peaqEvmWETH, peaqEvmWBTC, peaqEvmDAI, peaqEvmUSDT],
      fees: [{ asset: glmr, min: 0.1 }],
    },
  ],
  transferrableAssets: [], // TODO populate when fees refactoring is complete
});
