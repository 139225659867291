import { Box, Divider, Grid, Group, Stack, Text, UnstyledButton } from '@mantine/core';
import cx from 'clsx';
import { ActionButton } from 'components/ActionButton';
import { OffsiteLink } from 'components/OffsiteLink';
import { WalletLogo } from 'components/WalletLogo';
import { useIsTablet } from 'hooks/useMedia';
import { useTranslation } from 'next-i18next';
import { QRCodeSVG } from 'qrcode.react';
import { useState } from 'react';
import classes from './WalletsPage.module.css';
import { WalletInfo } from './interfaces';
interface WalletsPageProps {
  wallets: WalletInfo[];
  isLoading?: boolean;
  onConnect: (walletInfo: WalletInfo) => void;
}
export function WalletsPage({
  isLoading,
  wallets,
  onConnect
}: WalletsPageProps) {
  const {
    t
  } = useTranslation();
  const isTablet = useIsTablet();
  const [selectedWallet, setSelectedWallet] = useState<WalletInfo | undefined>(wallets.at(0));
  return <Grid mih={430} data-sentry-element="Grid" data-sentry-component="WalletsPage" data-sentry-source-file="WalletsPage.tsx">
      <Grid.Col span={{
      xs: 12,
      sm: 5
    }} data-sentry-element="unknown" data-sentry-source-file="WalletsPage.tsx">
        <Stack justify={'flex-start'} gap={6} data-sentry-element="Stack" data-sentry-source-file="WalletsPage.tsx">
          {wallets.map((wallet, index) => <UnstyledButton key={index} onClick={() => setSelectedWallet(wallet)} className={cx(classes.button, {
          [classes.selected]: selectedWallet?.name === wallet.name && selectedWallet?.version === wallet.version
        })}>
              <Group gap={'xs'}>
                <WalletLogo display={'inline-block'} wallet={wallet.name} />
                {wallet.name} {wallet.version && `v${wallet.version}`}
              </Group>
            </UnstyledButton>)}
        </Stack>
      </Grid.Col>
      <Grid.Col span={{
      xs: 12,
      sm: 1
    }} ta={'center'} data-sentry-element="unknown" data-sentry-source-file="WalletsPage.tsx">
        <Divider display={isTablet ? 'block' : 'inline-block'} h={isTablet ? '1px' : '100%'} w={isTablet ? '100%' : 'auto'} orientation={isTablet ? 'horizontal' : 'vertical'} data-sentry-element="Divider" data-sentry-source-file="WalletsPage.tsx" />
      </Grid.Col>
      <Grid.Col span={{
      xs: 12,
      sm: 6
    }} data-sentry-element="unknown" data-sentry-source-file="WalletsPage.tsx">
        <Stack h={'100%'} justify={'space-between'} gap={'xs'} data-sentry-element="Stack" data-sentry-source-file="WalletsPage.tsx">
          {selectedWallet && <>
              <Box>
                <Text>{selectedWallet.primary}</Text>
                {selectedWallet.secondary && <Text mt={'sm'}>{selectedWallet.secondary}</Text>}
              </Box>
              {selectedWallet.isConnected ? <Text ta={'center'} mb={'xs'}>
                  {t('walletSetup.isConnected')}
                </Text> : selectedWallet.isAvailable ? <ActionButton loading={isLoading} onClick={() => onConnect(selectedWallet)}>
                  {t('connectWallet')}
                </ActionButton> : <Box>
                  {!isTablet && <Box ta={'center'} mb={'xl'}>
                      <Box p={'xs'} mb={'xs'} display={'inline-flex'} className={classes.qrWrapper}>
                        <QRCodeSVG value={selectedWallet.link.url} size={220} />
                      </Box>
                    </Box>}
                  <Box ta={'center'} pb={'xs'}>
                    <OffsiteLink link={selectedWallet.link.url} label={selectedWallet.link.label} fz={'md'} mb={'xs'} />
                  </Box>
                </Box>}
            </>}
        </Stack>
      </Grid.Col>
    </Grid>;
}