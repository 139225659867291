import { atom } from 'recoil';
import { createLocalStorageEffect } from 'recoil/effects';
import { FiatCurrencies } from 'types/fiatCurrencies';
import { UserSettings } from './interfaces';

export const SMALL_BALANCE_FILTERING_VALUE = 10;

export const userSettingsAtom = atom<UserSettings>({
  key: 'userSettings',
  default: {
    fiatCurrency: FiatCurrencies.USD,
    areBalancesVisible: true,
    smallBalanceFilteringValue: SMALL_BALANCE_FILTERING_VALUE,
  },
  effects: [createLocalStorageEffect<UserSettings>('user-settings')],
});
