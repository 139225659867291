export enum SubscanReferendumStatus {
  SUBMITTED = 'Submitted',
  DECISION = 'Decision',
  CONFIRM_STARTED = 'ConfirmStarted',
  CONFIRM = 'Confirm',
  APPROVED = 'Approved',
  CONFIRM_ABORTED = 'ConfirmAborted',
  REJECTED = 'Rejected',
  CANCELLED = 'Cancelled',
  TIMEOUT = 'Timeout',
  KILLED = 'Killed',
  EXECUTED = 'Executed',
  EXECUTED_FAILED = 'ExecutedFailed',
}

export enum ReferendumStatus {
  SUBMITTED = 'Submitted',
  DECIDING = 'Deciding',
  CONFIRMING = 'Confirming',
  CONFIRMED = 'Confirmed',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  TIMED_OUT = 'TimedOut',
  CANCELLED = 'Cancelled',
  KILLED = 'Killed',
}

export const SubscanReferendaStatusMap = new Map<
  SubscanReferendumStatus,
  ReferendumStatus
>([
  [SubscanReferendumStatus.SUBMITTED, ReferendumStatus.SUBMITTED],
  [SubscanReferendumStatus.DECISION, ReferendumStatus.DECIDING],
  [SubscanReferendumStatus.CONFIRM_STARTED, ReferendumStatus.CONFIRMING],
  [SubscanReferendumStatus.CONFIRM, ReferendumStatus.CONFIRMING],
  [SubscanReferendumStatus.APPROVED, ReferendumStatus.APPROVED],
  [SubscanReferendumStatus.CONFIRM_ABORTED, ReferendumStatus.DECIDING],
  [SubscanReferendumStatus.REJECTED, ReferendumStatus.REJECTED],
  [SubscanReferendumStatus.CANCELLED, ReferendumStatus.CANCELLED],
  [SubscanReferendumStatus.TIMEOUT, ReferendumStatus.TIMED_OUT],
  [SubscanReferendumStatus.KILLED, ReferendumStatus.KILLED],
  [SubscanReferendumStatus.EXECUTED, ReferendumStatus.APPROVED],
  [SubscanReferendumStatus.EXECUTED_FAILED, ReferendumStatus.APPROVED],
]);

export enum ReferendumFailingReason {
  NOT_ENOUGH_APPROVAL = 'not_enough_approval',
  NOT_ENOUGH_SUPPORT = 'not_enough_support',
}

export type ReferendumPassingStatus = {
  isPassing: boolean;
  failingReason?: ReferendumFailingReason;
  whenBlock: number;
};
