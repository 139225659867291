import { CandidateIdentity } from 'hooks/queries/useIdentities';

export function sliceEllipsis(full?: string, pre = 10, post = 8): string {
  if (!full) {
    return '';
  }

  if (full.length < pre) {
    return full;
  }

  return `${full.slice(0, pre)}....${post === 0 ? '' : full.slice(post * -1)}`;
}

export function candidateDisplay(
  identity: CandidateIdentity,
  address?: string,
  pre?: number,
  post?: number,
): string {
  return identity?.display || sliceEllipsis(address, pre, post);
}

export function capitalize(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const isHtmlString = (str: string): boolean =>
  /<(br|basefont|hr|input|source|frame|param|area|meta|!--|col|link|option|base|img|wbr|!DOCTYPE).*?>|<(a|abbr|acronym|address|applet|article|aside|audio|b|bdi|bdo|big|blockquote|body|button|canvas|caption|center|cite|code|colgroup|command|datalist|dd|del|details|dfn|dialog|dir|div|dl|dt|em|embed|fieldset|figcaption|figure|font|footer|form|frameset|head|header|hgroup|h1|h2|h3|h4|h5|h6|html|i|iframe|ins|kbd|keygen|label|legend|li|map|mark|menu|meter|nav|noframes|noscript|object|ol|optgroup|output|p|pre|progress|q|rp|rt|ruby|s|samp|script|section|select|small|span|strike|strong|style|sub|summary|sup|table|tbody|td|textarea|tfoot|th|thead|time|title|tr|track|tt|u|ul|var|video).*?<\/\2>/i.test(
    str,
  );

export function compactNumber(
  locale = 'en',
  numberString: string | number | undefined,
  withSign = true,
): string {
  const formatter = Intl.NumberFormat(locale, { notation: 'compact' });

  return `${withSign ? '≈ ' : ''}${formatter.format(
    Number(numberString as string),
  )}`;
}

export function isAddressEqual(addressA?: string, addressB?: string): boolean {
  return addressA?.toLowerCase() === addressB?.toLowerCase();
}
