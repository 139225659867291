import { useLocalStorage } from '@mantine/hooks';
import { moonbeam } from '@moonbeam-network/xcm-config';
import { moonChainsKeys } from 'configs/chains';
import { useRouter } from 'next/router';

export function useMoonChainKeyParam(): string {
  const [storageValue] = useLocalStorage<string>({
    key: 'selected-moon-chain',
  });
  const router = useRouter();
  const { moonChainKey } = router.query;
  const param = Array.isArray(moonChainKey) ? moonChainKey.at(0) : moonChainKey;

  const network = param && moonChainsKeys.includes(param) ? param : undefined;

  return network || storageValue || moonbeam.key;
}
