// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"2a4f421404bdf6d18b1a71a3ea83fb97d74a137e"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";const NODE_ENV = process.env.NODE_ENV;
const NEXT_PUBLIC_DEPLOYMENT = process.env.NEXT_PUBLIC_DEPLOYMENT;
const SENTRY_ENABLED = process.env.SENTRY_ENABLED === 'true';

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://e15ab3b8aee07c99db59f324efb615ac@o4505765694996480.ingest.us.sentry.io/4508364598870016',

  // Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  enabled:
    (NODE_ENV === 'production' && NEXT_PUBLIC_DEPLOYMENT !== 'local') ||
    SENTRY_ENABLED,
  environment: NEXT_PUBLIC_DEPLOYMENT,
});
