import { Modal, Stack } from '@mantine/core';
import { MoonChainButton } from 'components/MoonChainButton';
import { moonbaseConfig, moonbeamConfig, moonriverConfig } from 'configs/chains';
import { useTranslation } from 'next-i18next';
import { Dispatch, SetStateAction } from 'react';
interface ChangeMoonChainModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}
export const ChangeMoonChainModal = ({
  isOpen,
  setIsOpen
}: ChangeMoonChainModalProps) => {
  const {
    t
  } = useTranslation();
  return <Modal centered opened={isOpen} onClose={() => setIsOpen(false)} title={t('changeNetwork')} closeButtonProps={{
    size: 'sm'
  }} data-sentry-element="Modal" data-sentry-component="ChangeMoonChainModal" data-sentry-source-file="ChangeMoonChainModal.tsx">
      <Stack align={'stretch'} gap={'sm'} mt={'sm'} data-sentry-element="Stack" data-sentry-source-file="ChangeMoonChainModal.tsx">
        <MoonChainButton chain={moonbeamConfig} data-sentry-element="MoonChainButton" data-sentry-source-file="ChangeMoonChainModal.tsx" />
        <MoonChainButton chain={moonriverConfig} data-sentry-element="MoonChainButton" data-sentry-source-file="ChangeMoonChainModal.tsx" />
        <MoonChainButton chain={moonbaseConfig} data-sentry-element="MoonChainButton" data-sentry-source-file="ChangeMoonChainModal.tsx" />
      </Stack>
    </Modal>;
};