import { glmr } from '@moonbeam-network/xcm-config';
import { MrlEvmChain } from 'services/mrl/chains/classes/MrlEvmChain';
import { arbitrumETHarbitrum } from 'services/mrl/tokens/chainTokens/mainnet';
import { arbitrum, moonbeam } from 'viem/chains';

export const arbitrumMrlChain = new MrlEvmChain({
  key: arbitrum.name.toLowerCase(),
  name: arbitrum.name,
  whName: 'Arbitrum',
  chainId: arbitrum.id,
  isAutomaticPossible: true,
  isTestChain: false,
  explorer: 'https://arbiscan.io',
  redeemChainId: arbitrum.id,
  redeemChainName: arbitrum.name,
  // TODO deprecate in favor of transferrableAssets
  destinations: [
    {
      chain: moonbeam.name,
      tokens: [arbitrumETHarbitrum],
      fees: [{ asset: glmr, min: 0.1 }], // fees need to be refactored
    },
  ],
  transferrableAssets: [
    {
      token: arbitrumETHarbitrum,
      destination: moonbeam.name,
      fees: [{ asset: glmr, min: 0.1 }], // fees need to be refactored
    },
  ],
});
