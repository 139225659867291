import {
  glmr,
  hydration,
  peaqChain,
  peaqEvm,
} from '@moonbeam-network/xcm-config';
import { MrlEvmChain } from 'services/mrl/chains/classes/MrlEvmChain';
import {
  ethereumDAI,
  ethereumETH,
  ethereumUSDCeth,
  ethereumUSDT,
  ethereumWBTC,
  ethereumWGLMR,
} from 'services/mrl/tokens/chainTokens/mainnet';
import { ethereumPEAQ } from 'services/mrl/tokens/chainTokens/mainnet/ethereumTokens';
import {
  mrlDAI,
  mrlUSDCWH,
  mrlUSDT,
  mrlWBTC,
  mrlWETH,
} from 'services/mrl/tokens/chainTokens/mainnet/parachainTokens';
import { mainnet, moonbeam } from 'viem/chains';

export const ethereumMainnetChain = new MrlEvmChain({
  key: mainnet.name.toLowerCase(),
  name: mainnet.name,
  whName: mainnet.name,
  chainId: mainnet.id,
  isAutomaticPossible: true,
  isTestChain: false,
  explorer: 'https://etherscan.io',
  redeemChainId: mainnet.id,
  redeemChainName: mainnet.name,
  // TODO deprecate in favor of transferrableAssets
  destinations: [
    {
      chain: moonbeam.name,
      tokens: [
        ethereumDAI,
        ethereumETH,
        ethereumUSDCeth,
        ethereumUSDT,
        ethereumWBTC,
        ethereumWGLMR,
      ],
      fees: [{ asset: glmr, min: 0.1 }],
    },
    {
      chain: hydration.name,
      tokens: [
        ethereumDAI,
        ethereumETH,
        ethereumUSDCeth,
        ethereumUSDT,
        ethereumWBTC,
      ],
      fees: [{ asset: glmr, min: 0.1 }],
    },
    {
      chain: peaqChain.name,
      tokens: [
        ethereumDAI,
        ethereumETH,
        ethereumUSDCeth,
        ethereumUSDT,
        ethereumWBTC,
        ethereumPEAQ,
      ],
      fees: [{ asset: glmr, min: 0.1 }],
    },
    {
      chain: peaqEvm.name,
      tokens: [
        ethereumDAI,
        ethereumETH,
        ethereumUSDCeth,
        ethereumUSDT,
        ethereumWBTC,
      ],
      fees: [{ asset: glmr, min: 0.1 }],
    },
  ],
  // Fees are taken from the xcm-sdk https://github.com/moonbeam-foundation/xcm-sdk/blob/main/packages/config/src/configs/moonbeam.ts
  transferrableAssets: [
    {
      token: ethereumETH,
      destination: hydration.name,
      fees: [{ asset: glmr, min: 0.1 }],
      destinationFee: 0.000002,
    },
    {
      token: ethereumWBTC,
      destination: hydration.name,
      fees: [{ asset: glmr, min: 0.1 }],
      destinationFee: 0.0000001,
    },
    {
      token: ethereumDAI,
      destination: hydration.name,
      fees: [{ asset: glmr, min: 0.1 }],
      destinationFee: 0.004,
    },
    {
      token: ethereumUSDCeth,
      destination: hydration.name,
      fees: [{ asset: glmr, min: 0.1 }],
      destinationFee: 0.004,
    },
    {
      token: ethereumUSDT,
      destination: hydration.name,
      fees: [{ asset: glmr, min: 0.1 }],
      destinationFee: 0.004,
    },
    {
      token: ethereumUSDCeth,
      destination: peaqEvm.name,
      fees: [{ asset: glmr, min: 0.1 }],
      destinationFee: 0.00001, // comes from xcm-sdk
      destinationMinBalance: { amount: 1, asset: mrlUSDCWH },
    },
    {
      token: ethereumUSDCeth,
      destination: peaqChain.name,
      fees: [{ asset: glmr, min: 0.1 }],
      destinationFee: 0.00001,
      destinationMinBalance: { amount: 1, asset: mrlUSDCWH }, // these come from PEAQ chain, ideally we would query them
    },
    {
      token: ethereumUSDT,
      destination: peaqEvm.name,
      fees: [{ asset: glmr, min: 0.1 }],
      destinationFee: 0.00001, // comes from xcm-sdk
      destinationMinBalance: { amount: 1, asset: mrlUSDT },
    },
    {
      token: ethereumUSDT,
      destination: peaqChain.name,
      fees: [{ asset: glmr, min: 0.1 }],
      destinationFee: 0.00001,
      destinationMinBalance: { amount: 1, asset: mrlUSDT },
    },
    {
      token: ethereumDAI,
      destination: peaqEvm.name,
      fees: [{ asset: glmr, min: 0.1 }],
      destinationFee: 0.00001,
      destinationMinBalance: { amount: 1, asset: mrlDAI },
    },
    {
      token: ethereumDAI,
      destination: peaqChain.name,
      fees: [{ asset: glmr, min: 0.1 }],
      destinationFee: 0.00001,
      destinationMinBalance: { amount: 1, asset: mrlDAI },
    },
    {
      token: ethereumETH,
      destination: peaqEvm.name,
      fees: [{ asset: glmr, min: 0.1 }],
      destinationFee: 0.000001,
      destinationMinBalance: { amount: 0.000000000001, asset: mrlWETH },
    },
    {
      token: ethereumETH,
      destination: peaqChain.name,
      fees: [{ asset: glmr, min: 0.1 }],
      destinationFee: 0.000001,
      destinationMinBalance: { amount: 0.000000000001, asset: mrlWETH },
    },
    {
      token: ethereumWBTC,
      destination: peaqEvm.name,
      fees: [{ asset: glmr, min: 0.1 }],
      destinationFee: 0.000001,
      destinationMinBalance: { amount: 0.00001, asset: mrlWBTC },
    },
    {
      token: ethereumWBTC,
      destination: peaqChain.name,
      fees: [{ asset: glmr, min: 0.1 }],
      destinationFee: 0.000001,
      destinationMinBalance: { amount: 0.00001, asset: mrlWBTC },
    },
    {
      token: ethereumPEAQ,
      destination: peaqChain.name,
      fees: [{ asset: glmr, min: 0.1 }],
      destinationFee: 0.1,
    },
  ],
});
