import {
  dev,
  hydrationAlphanet,
  moonbaseAlpha,
  moonbaseBeta,
  peaqAlphanet,
  peaqEvmAlphanet,
} from '@moonbeam-network/xcm-config';
import { MrlEvmChain } from 'services/mrl/chains/classes/MrlEvmChain';
import {
  fantomTestnetFTM,
  fantomTestnetWFTM,
  fantomTestnetWGLMR,
} from 'services/mrl/tokens/chainTokens';
import { fantomTestnetAGNG } from 'services/mrl/tokens/chainTokens/testnet/fantomTestnet';
import { fantom, fantomTestnet } from 'viem/chains';

export const fantomTestnetMrlChain = new MrlEvmChain({
  key: 'fantom-testnet',
  name: fantomTestnet.name,
  whName: fantom.name,
  chainId: fantomTestnet.id,
  isAutomaticPossible: true,
  isTestChain: true,
  explorer: 'https://testnet.ftmscan.com',
  redeemChainId: fantomTestnet.id,
  redeemChainName: fantomTestnet.name,
  // TODO deprecate in favor of trasnferrableTokens
  destinations: [
    {
      chain: moonbaseAlpha.name,
      tokens: [fantomTestnetFTM, fantomTestnetWGLMR],
      fees: [{ asset: dev, min: 0.1 }],
    },
    {
      chain: moonbaseBeta.name,
      tokens: [fantomTestnetFTM, fantomTestnetWFTM],
      fees: [{ asset: dev, min: 0.1 }],
    },
    {
      chain: hydrationAlphanet.name,
      tokens: [fantomTestnetFTM],
      fees: [{ asset: dev, min: 0.1 }],
    },
    {
      chain: peaqAlphanet.name,
      tokens: [fantomTestnetFTM, fantomTestnetAGNG],
      fees: [{ asset: dev, min: 0.1 }],
    },
    {
      chain: peaqEvmAlphanet.name,
      tokens: [fantomTestnetFTM],
      fees: [{ asset: dev, min: 0.1 }],
    },
  ],
  transferrableAssets: [
    {
      token: fantomTestnetFTM,
      destination: hydrationAlphanet.name,
      fees: [{ asset: dev, min: 0.1 }],
      destinationFee: 0.01, // TODO put the asset as well?
    },
    {
      token: fantomTestnetFTM,
      destination: peaqAlphanet.name,
      fees: [{ asset: dev, min: 0.1 }],
      destinationFee: 0.000001,
    },
    {
      token: fantomTestnetAGNG,
      destination: peaqAlphanet.name,
      fees: [{ asset: dev, min: 0.1 }],
      destinationFee: 0.01,
    },
    {
      token: fantomTestnetFTM,
      destination: peaqEvmAlphanet.name,
      fees: [{ asset: dev, min: 0.1 }],
      destinationFee: 0.000001,
    },
    {
      token: fantomTestnetFTM,
      destination: moonbaseBeta.name,
      fees: [{ asset: dev, min: 0.1 }],
      destinationFee: 0.06,
    },
    {
      token: fantomTestnetFTM,
      destination: moonbaseAlpha.name,
      fees: [{ asset: dev, min: 0.1 }],
    },
  ],
});
