import { MrlToken } from 'services/mrl/tokens/Token';

export const arbitrumETHarbitrum = MrlToken.create({
  symbol: 'ETH',
  decimals: 18,
  isNative: true,
  chain: 'Arbitrum',
  key: 'eth',
  name: 'ETHarbitrum',
});
