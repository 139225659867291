import { moonbeam, moonriver } from '@moonbeam-network/xcm-config';
import { moonChainConfigs } from 'configs/chains';
import { useMoonChainKeyParam } from 'hooks/useMoonChainParam';
import { MoonChain } from 'types/MoonChain';

export function useMoonChain(): MoonChain {
  return moonChainConfigs[useMoonChainKeyParam()];
}

export function useIsMoonbeam(): boolean {
  return useMoonChain().name === moonbeam.name;
}

export function useIsMoonriver(): boolean {
  return useMoonChain().name === moonriver.name;
}
