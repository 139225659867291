import EU from 'images/flags/eu-flag-icon.svg';
import US from 'images/flags/us-flag-icon.svg';
import { FiatCurrencies } from 'types/fiatCurrencies';

const FiatImages: Record<FiatCurrencies, string> = {
  [FiatCurrencies.USD]: US,
  [FiatCurrencies.EUR]: EU,
};

export function getFiatImage(fiat: FiatCurrencies): string {
  return FiatImages[fiat];
}
