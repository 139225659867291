import { internalUrls } from 'configs/urls';

export function isTrustedUrl(url: string): boolean {
  return internalUrls.some((u) => url.includes(u)) || isSameOrigin(url);
}

export function isSameOrigin(url: string): boolean {
  try {
    const givenUrl = new URL(url);

    return givenUrl.origin === window.location.origin;
  } catch {
    return false;
  }
}

export function getUrlDomainFromString(url: string): string {
  try {
    const urlObj = new URL(url);

    return urlObj.hostname;
  } catch (error) {
    console.error(error);

    return '';
  }
}
