import { ActionIcon } from '@mantine/core';
import { useTranslation } from 'next-i18next';
import { IoEye, IoEyeOff } from 'react-icons/io5';
import { useUserSettings } from 'recoil/userSettings';
import classes from './Buttons.module.css';
export const HideBalances = () => {
  const {
    t
  } = useTranslation();
  const {
    areBalancesVisible,
    toggleBalancesVisibility
  } = useUserSettings();
  return <ActionIcon className={classes.icon} size={52} variant={'filled'} onClick={toggleBalancesVisibility} title={areBalancesVisible ? t('hideBalances') : t('showBalances')} data-sentry-element="ActionIcon" data-sentry-component="HideBalances" data-sentry-source-file="HideBalances.tsx">
      {areBalancesVisible ? <IoEyeOff size={25} /> : <IoEye size={25} />}
    </ActionIcon>;
};