import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

export function useIsMobile(): boolean | undefined {
  const theme = useMantineTheme();

  return useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
}

export function useIsTablet(): boolean | undefined {
  const theme = useMantineTheme();

  return useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
}

export function useIsWideTablet(): boolean | undefined {
  const theme = useMantineTheme();

  return useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
}

export function useIsDesktop(): boolean | undefined {
  const theme = useMantineTheme();

  return useMediaQuery(`(min-width: ${theme.breakpoints.md})`);
}

export function useIsWideScreen(): boolean | undefined {
  const theme = useMantineTheme();

  return useMediaQuery(`(min-width: ${theme.breakpoints.lg})`);
}
