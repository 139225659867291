import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { mobileNavbarAtom } from './atom';

export function useMobileNavbar(): {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
} {
  const [{ isOpen }, setIsOpen] = useRecoilState(mobileNavbarAtom);

  const open = useCallback(() => {
    setIsOpen({ isOpen: true });
  }, [setIsOpen]);

  const close = useCallback(() => {
    setIsOpen({ isOpen: false });
  }, [setIsOpen]);

  const toggle = useCallback(() => {
    setIsOpen({ isOpen: !isOpen });
  }, [setIsOpen, isOpen]);

  return {
    isOpen,
    open,
    close,
    toggle,
  };
}
