import { Divider, Group, Text, Tooltip, UnstyledButton, useMantineTheme } from '@mantine/core';
import { OffsiteLink } from 'components/OffsiteLink';
import { useLatestBlock } from 'hooks/queries/useLatestBlock';
import { useApi } from 'hooks/useApi';
import { useMoonChainKeyParam } from 'hooks/useMoonChainParam';
import { useThemeLogos } from 'hooks/useThemeLogos';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { IoEllipse } from 'react-icons/io5';
import { useIsTermsModalOpen } from 'recoil/isTermsModalOpen';
import classes from './Footer.module.css';
export const Footer = () => {
  const {
    t
  } = useTranslation();
  const moonChainKey = useMoonChainKeyParam();
  const theme = useMantineTheme();
  const api = useApi();
  const latestBlock = useLatestBlock();
  const {
    open
  } = useIsTermsModalOpen();
  const logo = useThemeLogos();
  return <Group justify={'space-between'} className={classes.footer} h={29} data-sentry-element="Group" data-sentry-component="Footer" data-sentry-source-file="Footer.tsx">
      <Group pl={15} pt={3} gap={'xs'} data-sentry-element="Group" data-sentry-source-file="Footer.tsx">
        <Text size={'xs'} data-sentry-element="Text" data-sentry-source-file="Footer.tsx">{t('network')}</Text>
        <Image alt={moonChainKey} src={logo} width={69} height={14} data-sentry-element="Image" data-sentry-source-file="Footer.tsx" />
        <Divider orientation={'vertical'} size={'md'} className={classes.divider} data-sentry-element="Divider" data-sentry-source-file="Footer.tsx" />
        <IoEllipse color={api ? theme.colors.moonbeam[9] : theme.colors.moonbeam[0]} data-sentry-element="IoEllipse" data-sentry-source-file="Footer.tsx" />
        <Tooltip disabled={!api} label={`${t('latestBlock')} #${latestBlock}`} data-sentry-element="Tooltip" data-sentry-source-file="Footer.tsx">
          <Text size={'xs'} data-sentry-element="Text" data-sentry-source-file="Footer.tsx">{t(api ? 'online' : 'connecting')}</Text>
        </Tooltip>
      </Group>
      <Group pr={15} pt={3} gap={'xs'} data-sentry-element="Group" data-sentry-source-file="Footer.tsx">
        <UnstyledButton className={classes.terms} onClick={open} data-sentry-element="UnstyledButton" data-sentry-source-file="Footer.tsx">
          <Text size={'xs'} data-sentry-element="Text" data-sentry-source-file="Footer.tsx">{t('termsOfService')}</Text>
        </UnstyledButton>
        <OffsiteLink label={t('privacyPolicy')} link={'https://moonbeam.foundation/privacy-policy/'} size={'xs'} data-sentry-element="OffsiteLink" data-sentry-source-file="Footer.tsx" />

        <Divider orientation={'vertical'} size={'md'} className={classes.divider} data-sentry-element="Divider" data-sentry-source-file="Footer.tsx" />
        <Text size={'xs'} data-sentry-element="Text" data-sentry-source-file="Footer.tsx">{t('pricesByCoinGecko')}</Text>
      </Group>
    </Group>;
};