import { useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { FiatCurrencies } from 'types/fiatCurrencies';
import { userSettingsAtom } from './atoms';
import { UserSettings } from './interfaces';

interface UseUserSettingsResponse extends UserSettings {
  setFiatCurrency: (value: UserSettings['fiatCurrency']) => void;
  hideBalances: () => void;
  showBalances: () => void;
  toggleBalancesVisibility: () => void;
  setBalanceFilteringValue: (value: number | string) => void;
}

export function useFiatCurrency(): FiatCurrencies {
  const settings = useRecoilValue(userSettingsAtom);

  return settings.fiatCurrency;
}

export function useUserSettings(): UseUserSettingsResponse {
  const [settings, setUserSettings] = useRecoilState(userSettingsAtom);

  const setFiatCurrency = useCallback(
    (value: UserSettings['fiatCurrency']) => {
      setUserSettings((prevSettings) => ({
        ...prevSettings,
        fiatCurrency: value,
      }));
    },
    [setUserSettings],
  );

  const hideBalances = useCallback(() => {
    setUserSettings((prevSettings) => ({
      ...prevSettings,
      areBalancesVisible: false,
    }));
  }, [setUserSettings]);

  const showBalances = useCallback(() => {
    setUserSettings((prevSettings) => ({
      ...prevSettings,
      areBalancesVisible: true,
    }));
  }, [setUserSettings]);

  const toggleBalancesVisibility = useCallback(() => {
    setUserSettings((prevSettings) => ({
      ...prevSettings,
      areBalancesVisible: !prevSettings.areBalancesVisible,
    }));
  }, [setUserSettings]);

  const setBalanceFilteringValue = useCallback(
    (value: number | string) => {
      setUserSettings((prevSettings) => ({
        ...prevSettings,
        smallBalanceFilteringValue: Number(value),
      }));
    },
    [setUserSettings],
  );

  return {
    ...settings,
    setFiatCurrency,
    hideBalances,
    showBalances,
    toggleBalancesVisibility,
    setBalanceFilteringValue,
  };
}
