import { atom } from 'recoil';
import { WalletType } from './interfaces';

export const isConnectWalletOpenAtom = atom({
  key: 'isConnectWalletOpen',
  default: {
    isOpen: false,
    type: WalletType.EVM,
  },
});
