import { Address } from 'viem';

type TokenParams = {
  address?: Address;
  decimals: number;
  chain: string;
  symbol: string;
  key: string;
  original?: string;
  wrappedKey?: string;
  isNative?: boolean;
  isSpam?: boolean;
  name: string;
};

export class MrlToken {
  private constructor(
    readonly decimals: number,
    readonly chain: string,
    readonly symbol: string,
    readonly key: string,
    readonly name: string,
    readonly original?: string,
    readonly wrappedKey?: string,
    readonly isNative: boolean = false,
    readonly isSpam: boolean = false,
    readonly address?: Address,
  ) {}

  public static create({
    address,
    decimals,
    chain,
    symbol,
    key,
    name,
    original,
    wrappedKey,
    isNative = false,
    isSpam = false,
  }: TokenParams): MrlToken {
    return new MrlToken(
      decimals,
      chain,
      symbol,
      key,
      name,
      original,
      wrappedKey,
      isNative,
      isSpam,
      address,
    );
  }
}
