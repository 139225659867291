import { ReactNode } from 'react';

export function hasName(data: unknown): data is { name: string } {
  return Boolean(
    typeof data === 'object' &&
      data &&
      'name' in data &&
      typeof data.name === 'string',
  );
}

declare global {
  interface Window {
    CBWSubscribe?: {
      createSubscriptionUI: (options: {
        partnerAddress: string;
        partnerName: string;
        modalTitle: string;
        modalBody: string;
        onSubscriptionChange: (isSubscribed: boolean) => void;
        onLoading: (isLoading: boolean) => void;
      }) => void;
      toggleSubscription: () => void;
      subscriptionElement: ReactNode | undefined;
    };
  }
}
