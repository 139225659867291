import { ActionIcon, Box, Group, Modal, Text, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useTranslation } from 'next-i18next';
import classes from './Buttons.module.css';
import { moonbeam, moonriver } from '@moonbeam-network/xcm-config';
import { CBSubscribeButton } from 'components/ConnectWallet/buttons/CBSubscribeButton';
import { useIsCBSubscribeDisabled } from 'hooks/flags/useIsCBSubscribeDisabled';
import { useMoonChainKeyParam } from 'hooks/useMoonChainParam';
import { TbMailPlus } from 'react-icons/tb';
import { capitalize } from 'utils/text';
const broadcastAddress = {
  [moonbeam.key]: '0xAE7b98A4CAa39B3F41011b6e2ECA61cC9191D274',
  [moonriver.key]: '0x8baf67e19248a33081670d8369834F72D7D87Ea0'
};
export const CBSubscribe = () => {
  const {
    t
  } = useTranslation();
  const [opened, {
    open,
    close
  }] = useDisclosure(false);
  const moonChain = useMoonChainKeyParam();
  const isCBSubscribeDisabled = useIsCBSubscribeDisabled();
  const partnerAddress = broadcastAddress[moonChain];
  if (!partnerAddress || isCBSubscribeDisabled) return null;
  return <>
      <Modal opened={opened} onClose={() => {
      close();

      // reset the previously mounted UI
      if (window.CBWSubscribe) {
        window.CBWSubscribe.subscriptionElement = undefined;
      }
    }} data-sentry-element="Modal" data-sentry-source-file="CBSubscribe.tsx">
        <Box px={'md'} data-sentry-element="Box" data-sentry-source-file="CBSubscribe.tsx">
          <Title ta={'center'} size={'h3'} fw={'bold'} data-sentry-element="Title" data-sentry-source-file="CBSubscribe.tsx">
            {t('subscribeToMessagingModal.title', {
            chain: capitalize(moonChain)
          })}
          </Title>
          <Text py={'xl'} data-sentry-element="Text" data-sentry-source-file="CBSubscribe.tsx">
            {t('subscribeToMessagingModal.body', {
            chain: capitalize(moonChain)
          })}
          </Text>
          <Group justify={'right'} data-sentry-element="Group" data-sentry-source-file="CBSubscribe.tsx">
            {opened && <CBSubscribeButton partnerAddress={partnerAddress} />}
          </Group>
        </Box>
      </Modal>
      <ActionIcon className={classes.icon} size={52} variant={'filled'} onClick={open} title={t('subscribeToWalletMessaging')} data-sentry-element="ActionIcon" data-sentry-source-file="CBSubscribe.tsx">
        <TbMailPlus size={25} data-sentry-element="TbMailPlus" data-sentry-source-file="CBSubscribe.tsx" />
      </ActionIcon>
    </>;
};