import { Box, NavLink, Tooltip, useMantineTheme } from '@mantine/core';
import { OffsiteLink } from 'components/OffsiteLink';
import { useRouteLink } from 'hooks/router/useRouteLink';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { ReactNode } from 'react';
import { IoOpenOutline } from 'react-icons/io5';
import { useRecoilValue } from 'recoil';
import { isNavbarCollapsedAtom } from 'recoil/isNavbarCollapsed';
import { useMobileNavbar } from 'recoil/mobileNavbar';
import classes from './NavbarMenu.module.css';
export interface MenuItemProps {
  label: string;
  path?: string;
  link?: string;
  tooltip?: ReactNode;
  icon?: ReactNode;
}
export function MenuItem({
  label,
  path,
  link,
  tooltip,
  icon
}: MenuItemProps) {
  // @TODO handle closed navbar when designer delivers handoff
  // biome-ignore lint/correctness/noUnusedVariables: <explanation>
  const isNavbarCollapsed = useRecoilValue(isNavbarCollapsedAtom);
  const {
    t
  } = useTranslation();
  const {
    isCurrentPath,
    fullPath
  } = useRouteLink(path);
  const theme = useMantineTheme();
  const {
    close
  } = useMobileNavbar();
  const navLink = <OffsiteLink style={{
    textDecoration: 'none'
  }} link={link as string} withIcon={false}>
      <NavLink component={'button'} py={'xs'} h={'auto'} leftSection={icon} className={isCurrentPath ? classes.active : undefined} label={t(label)} pl={44} rightSection={<IoOpenOutline color={theme.other.colors.action} />} onClick={close} />
    </OffsiteLink>;
  const navLinkInternal = <NavLink href={fullPath} component={Link} py={'xs'} h={'auto'} leftSection={icon} className={isCurrentPath ? classes.active : undefined} label={t(label)} pl={44} />;
  return <Tooltip label={tooltip} position={'right'} disabled={!tooltip} multiline withArrow w={400} openDelay={1000} transitionProps={{
    duration: 400
  }} data-sentry-element="Tooltip" data-sentry-component="MenuItem" data-sentry-source-file="MenuItem.tsx">
      <Box data-sentry-element="Box" data-sentry-source-file="MenuItem.tsx">{link ? navLink : navLinkInternal}</Box>
    </Tooltip>;
}