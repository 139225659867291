import { Button, useMantineColorScheme } from '@mantine/core';
import { useIsMobile, useIsTablet } from 'hooks/useMedia';
import { useThemeColors } from 'hooks/useThemeColors';
import { useTranslation } from 'next-i18next';
import { IoCopy } from 'react-icons/io5';
import { isDarkScheme } from 'utils/theme';
import classes from './InputPasteButton.module.css';
export interface Props {
  onClick: (value: string | undefined) => void;
}
export const InputPasteButton = ({
  onClick
}: Props) => {
  const {
    t
  } = useTranslation();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const {
    colorScheme
  } = useMantineColorScheme();
  const {
    themeColors
  } = useThemeColors();
  return <Button variant={'transparent'} className={classes.button} classNames={{
    root: classes.root
  }} leftSection={isMobile ? null : <IoCopy className={classes.color} />} size={isMobile || isTablet ? 'compact-sm' : 'sm'} onClick={() => navigator.clipboard.readText().then(onClick)} style={({
    white
  }) => ({
    color: isDarkScheme(colorScheme) ? white : themeColors.texts
  })} data-sentry-element="Button" data-sentry-component="InputPasteButton" data-sentry-source-file="InputPasteButton.tsx">
      {t('paste')}
    </Button>;
};