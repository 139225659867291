import { Box, ButtonProps } from '@mantine/core';
import { useIsDarkMode } from 'hooks/useIsDarkMode';
import defReversed from 'images/logos/default-chain-reversed.png';
import def from 'images/logos/default-chain.png';
import Image, { StaticImageData } from 'next/image';
import { logos, reversedLogos } from './logos';
export interface Props extends Omit<ButtonProps, 'size'> {
  chain: string;
  size?: number;
}
export const ChainLogo = ({
  chain,
  size = 24,
  className,
  ...others
}: Props) => {
  const isDarkMode = useIsDarkMode();
  function getLogo(): StaticImageData {
    const logo = logos[chain];
    const reversedLogo = reversedLogos[chain];
    const defaultLogo = isDarkMode ? defReversed : def;
    if (isDarkMode && reversedLogo) {
      return reversedLogo;
    }
    return logo || defaultLogo;
  }
  return <Box className={className} w={size} h={size} {...others} data-sentry-element="Box" data-sentry-component="ChainLogo" data-sentry-source-file="ChainLogo.tsx">
      <Image src={getLogo()} alt={chain} width={size} height={size} data-sentry-element="Image" data-sentry-source-file="ChainLogo.tsx" />
    </Box>;
};