import { SubscanReferendumStatus } from 'types/governance/referendaStatus';

export type TimelineStep = TimelineStepConversion | SubscanReferendumStatus;

export enum TimelineStepConversion {
  DECISION_DEPOSIT = 'DecisionDeposit',
  DECIDING_PHASE = 'DecidingPhase',
  CONFIRMING_STAGE = 'ConfirmingStage',
  TIMED_OUT = 'TimedOut',
  CONFIRMED = 'Confirmed',
}

export const SubscanStatusTimelineStepMap = new Map<
  SubscanReferendumStatus,
  TimelineStep
>([
  [SubscanReferendumStatus.SUBMITTED, SubscanReferendumStatus.SUBMITTED],
  [SubscanReferendumStatus.DECISION, TimelineStepConversion.DECIDING_PHASE],
  [
    SubscanReferendumStatus.CONFIRM_STARTED,
    TimelineStepConversion.CONFIRMING_STAGE,
  ],
  [SubscanReferendumStatus.CONFIRM, TimelineStepConversion.CONFIRMED],
  [SubscanReferendumStatus.APPROVED, SubscanReferendumStatus.APPROVED],
  [
    SubscanReferendumStatus.CONFIRM_ABORTED,
    SubscanReferendumStatus.CONFIRM_ABORTED,
  ],
  [SubscanReferendumStatus.REJECTED, SubscanReferendumStatus.REJECTED],
  [SubscanReferendumStatus.CANCELLED, SubscanReferendumStatus.CANCELLED],
  [SubscanReferendumStatus.TIMEOUT, TimelineStepConversion.TIMED_OUT],
  [SubscanReferendumStatus.KILLED, SubscanReferendumStatus.KILLED],
  [SubscanReferendumStatus.EXECUTED, SubscanReferendumStatus.EXECUTED],
  [
    SubscanReferendumStatus.EXECUTED_FAILED,
    SubscanReferendumStatus.EXECUTED_FAILED,
  ],
]);
