import { ActionIcon, useMantineColorScheme } from '@mantine/core';
import { useTranslation } from 'next-i18next';
import { IoMoonOutline, IoSunnyOutline } from 'react-icons/io5';
import { isDarkScheme } from 'utils/theme';
export const DarkModeToggle = () => {
  const {
    t
  } = useTranslation();
  const {
    colorScheme,
    toggleColorScheme
  } = useMantineColorScheme();
  const isDark = isDarkScheme(colorScheme);
  return <ActionIcon onClick={() => toggleColorScheme()} size={30} title={isDark ? t('icons.lightMode') : t('icons.darkMode')} data-testid={'darkmode-toggle-button'} data-sentry-element="ActionIcon" data-sentry-component="DarkModeToggle" data-sentry-source-file="DarkModeToggle.tsx">
      {isDark ? <IoSunnyOutline size={20} /> : <IoMoonOutline size={20} />}
    </ActionIcon>;
};