import { glmr } from '@moonbeam-network/xcm-config';
import { MrlEvmChain } from 'services/mrl/chains/classes/MrlEvmChain';
import { fantomWGLMR } from 'services/mrl/tokens/chainTokens/mainnet';
import { fantomFTM } from 'services/mrl/tokens/chainTokens/mainnet/fantomTokens';
import { fantom, moonbeam } from 'viem/chains';

export const fantomMrlChain = new MrlEvmChain({
  key: fantom.name.toLowerCase(),
  name: fantom.name,
  whName: fantom.name,
  chainId: fantom.id,
  isAutomaticPossible: true,
  isTestChain: false,
  explorer: 'https://ftmscan.com',
  redeemChainId: fantom.id,
  redeemChainName: fantom.name,
  // TODO deprecate in favor of transferrableAssets
  destinations: [
    {
      chain: moonbeam.name,
      tokens: [fantomWGLMR, fantomFTM],
      fees: [{ asset: glmr, min: 0.1 }], // fees need to be refactored
    },
  ],
  transferrableAssets: [
    {
      token: fantomWGLMR,
      destination: moonbeam.name,
      fees: [{ asset: glmr, min: 0.1 }],
    },
    {
      token: fantomFTM,
      destination: moonbeam.name,
      fees: [{ asset: glmr, min: 0.1 }],
    },
  ],
});
