import { Asset, AssetAmount } from '@moonbeam-network/xcm-types';
import { toBigInt } from '@moonbeam-network/xcm-utils';
import { Chain as WhChain } from '@wormhole-foundation/sdk-base';
import { CovalentBalanceItem } from 'server/procedures/wallet/query/fetchWalletBalances';
import { MrlEvmChain, MrlEvmParachain } from 'services/mrl/chains/classes';
import { MrlParachain } from 'services/mrl/chains/classes/MrlParachain';
import { MrlAssetBalance } from 'services/mrl/tokens/MrlAssetBalance';
import { MrlToken } from 'services/mrl/tokens/Token';
import { MoonChain } from 'types/MoonChain';
import { ObjectValues } from 'types/common';
import { Address } from 'viem';
import { Chain } from 'viem/chains';

export type Fee = { asset: Asset; min: number; address?: Address };

export type ParachainToken = {
  asset: Asset;
  name?: string;
  moonToken: MrlToken;
  address?: Address;
};

export type MrlParachainDestination = {
  chain: string;
  tokens: ParachainToken[];
  fees?: Fee[];
  destinationFee?: Fee;
};

export type MrlDestination = {
  chain: string;
  tokens: MrlToken[];
  fees?: Fee[]; // for EVM - Moonbeam will be always just moonbeam
  destinationFee?: Fee;
};

export type DestinationAmount = {
  amount: number;
  asset: ParachainToken;
};

export type MrlTransferrableParachainAssets = {
  token: ParachainToken;
  destination: string;
  fees?: Fee[];
  destinationFee?: number;
};

export type MrlTransferrableAssets = {
  token: MrlToken;
  destination: string;
  fees?: Fee[];
  destinationFee?: number;
  destinationMinBalance?: DestinationAmount; // TODO apply for Polkadot to Eth as well when necessary
};

export const Ecosystem = {
  EvmChains: 'EvmChains',
  Dotsama: 'Dotsama',
} as const;

export type Ecosystem = ObjectValues<typeof Ecosystem>;

export const MrlChainType = {
  EvmChain: 'evm-chain',
  Parachain: 'parachain',
  EvmParachain: 'evm-parachain',
  MoonChain: 'moon-chain',
} as const;

export type MrlChainType = ObjectValues<typeof MrlChainType>;

export enum TokenFilter {
  Address = 'Address',
  Key = 'Key',
}

// this params are required for filtering balances, balances have more fields but these are the most important for filtering
export type FilterParam = { address?: string; key: string; isNative?: boolean };

export interface BaseChainConstructorParams {
  key: string;
  ecosystem: Ecosystem;
  isTestChain?: boolean;
  name: string;
  type: MrlChainType;
  isEvm: boolean;
  isAutomaticPossible: boolean;
  redeemChainId: number;
  redeemChainName: string;
  whName: WhChain;
  genesisHash?: string;
  ss58Format?: number;
  parachainId?: number;
  ws?: string | string[];
  explorer?: string;
  viemChain?: Chain;
}

export abstract class MrlBaseChain {
  readonly ecosystem?: Ecosystem;

  readonly isTestChain: boolean;

  readonly key: string;

  readonly name: string;

  readonly type: MrlChainType;

  readonly isEvm: boolean;

  readonly isAutomaticPossible: boolean;

  readonly chainId?: number;

  readonly redeemChainId: number;

  readonly redeemChainName: string;

  readonly whName: WhChain;

  readonly genesisHash?: string;

  readonly ss58Format?: number;

  readonly parachainId?: number;

  readonly ws?: string | string[];

  readonly explorer?: string;

  readonly viemChain?: Chain;

  // TODO: THIS HAS TO BE REFACTORED, I THINK WE SHOULD USE SOME OBJECT INSTEAD OF ARRAYS, IT WILL SIMPLIFY A LOT OF THINGS
  abstract destinations: MrlDestination[] | MrlParachainDestination[];

  abstract transferrableAssets:
    | MrlTransferrableAssets[]
    | MrlTransferrableParachainAssets[];

  constructor({
    isTestChain = false,
    key,
    name,
    type,
    isEvm,
    isAutomaticPossible,
    ecosystem,
    redeemChainId,
    redeemChainName,
    whName,
    genesisHash,
    ss58Format,
    parachainId,
    ws,
    explorer,
    viemChain,
  }: BaseChainConstructorParams) {
    this.ecosystem = ecosystem;
    this.isTestChain = isTestChain;
    this.key = key;
    this.name = name;
    this.type = type;
    this.isEvm = isEvm;
    this.isAutomaticPossible = isAutomaticPossible;
    this.redeemChainId = redeemChainId;
    this.redeemChainName = redeemChainName;
    this.whName = whName;
    this.genesisHash = genesisHash;
    this.ss58Format = ss58Format;
    this.parachainId = parachainId;
    this.ws = ws;
    this.explorer = explorer;
    this.viemChain = viemChain;
  }

  #isParachainToken(token: MrlToken | ParachainToken): token is ParachainToken {
    return 'asset' in token;
  }

  isAnyParachain(): this is MrlParachain | MrlEvmParachain {
    return (
      this.type === MrlChainType.Parachain ||
      this.type === MrlChainType.EvmParachain
    );
  }

  isParachain(): this is MrlParachain {
    return this.type === MrlChainType.Parachain;
  }

  isEvmParachain(): this is MrlEvmParachain {
    return this.type === MrlChainType.EvmParachain;
  }

  isEvmChain(): this is MrlEvmChain {
    return this.type === MrlChainType.EvmChain;
  }

  isMoonChain(): this is MoonChain {
    return this.type === MrlChainType.MoonChain;
  }

  useSubstrateWallet(): boolean {
    return false;
  }

  isEvmPrecompileParachain(): boolean {
    // biome-ignore lint/correctness/useHookAtTopLevel: <explanation>
    return this.isEvmParachain() && !this.useSubstrateWallet();
  }

  getDestinationChain(
    chainName?: string,
  ): MrlDestination | MrlParachainDestination | undefined {
    if (!chainName) {
      return undefined;
    }

    return this.destinations.find((d) => d.chain === chainName);
  }

  getDestinationTokens(chainName?: string): MrlToken[] | ParachainToken[] {
    if (!chainName) {
      return [];
    }

    const dest = this.getDestinationChain(chainName);

    return dest?.tokens || [];
  }

  // In the base class are used for Parachain balances
  createBridgeableAssetsForParachain(
    balances?: AssetAmount[],
    destChainName?: string,
  ): MrlAssetBalance[] | undefined {
    const destTokens = <ParachainToken[]>(
      this.getDestinationTokens(destChainName)
    );

    if (!destChainName || !balances || !destTokens) {
      return [];
    }

    return destTokens?.map((token) => {
      const foundBalance = balances?.find((balance) => {
        return token.asset.key === balance.key;
      });

      return MrlAssetBalance.createFromParaTokenAndBalance(token, foundBalance);
    });
  }

  createBridgeableAssetsForEVM(
    balances?: CovalentBalanceItem[],
    destChainName?: string,
  ): MrlAssetBalance[] | undefined {
    const destTokens = <MrlToken[]>this.getDestinationTokens(destChainName);

    if (!destChainName || !balances || !destTokens) {
      return [];
    }

    return destTokens?.map((token) => {
      const foundInBalances = balances?.find((balance) => {
        const sameAddress =
          token.address?.toLowerCase() === balance.address?.toLowerCase();
        const isNative = balance.isNative && token.isNative;

        return sameAddress || isNative;
      });

      return MrlAssetBalance.createFromMrlTokenAndBalance(
        token,
        foundInBalances,
      );
    });
  }

  createAssets(
    balances?: CovalentBalanceItem[] | AssetAmount[],
  ): MrlAssetBalance[] | undefined {
    return balances?.map((balance) => {
      return MrlAssetBalance.createFromAnyBalance(balance);
    });
  }

  // FIXME: can be improved, now is too ugly but this annoying  typescript is complaining
  getTokenMoonAddress(chainName?: string, key?: string): string | undefined {
    const tokens = this.getDestinationTokens(chainName);

    if (this.isAnyParachain()) {
      const found = tokens.find(
        (t) => this.#isParachainToken(t) && t.asset?.key === key,
      );

      if (found && this.#isParachainToken(found)) {
        return found.moonToken?.address;
      }
    }
  }

  filterFeeBalances(
    balances?: MrlAssetBalance[],
    destChainName?: string,
  ): (MrlAssetBalance & { min: number })[] | undefined {
    const dest = this.getDestinationChain(destChainName) || {};

    if ('fees' in dest || {}) {
      const fees = (dest as MrlParachainDestination).fees || [];

      return balances
        ?.map((balance) => {
          const foundFee = fees.find((fee) => fee.asset.key === balance.key);

          if (foundFee) {
            return { ...balance, min: foundFee.min };
          }
        })
        .filter(Boolean) as (MrlAssetBalance & { min: number })[] | undefined;
    }
  }

  matchTransferrableAssetWithDestination(
    token: CovalentBalanceItem | null,
    destChainName?: string,
  ): MrlTransferrableAssets | MrlTransferrableParachainAssets | undefined {
    return this.transferrableAssets.find(
      (asset) =>
        asset.destination === destChainName &&
        token &&
        ((asset.token as MrlToken).symbol === token?.symbol ||
          (asset.token as ParachainToken).asset?.key === token?.key),
    );
  }

  getDestinationFeeAmountForToken(
    token: CovalentBalanceItem | null,
    destChainName?: string,
  ): bigint | undefined {
    const transferrableAsset = this.matchTransferrableAssetWithDestination(
      token,
      destChainName,
    );

    return transferrableAsset?.destinationFee && token
      ? toBigInt(transferrableAsset.destinationFee, token?.decimals)
      : 0n;
  }

  getDestinationMinBalanceAmountForToken(
    token: CovalentBalanceItem | null,
    destChainName?: string,
    balances?: CovalentBalanceItem[],
  ): bigint | undefined {
    const transferrableAsset = this.matchTransferrableAssetWithDestination(
      token,
      destChainName,
    );

    const destinationMinBalance = (transferrableAsset as MrlTransferrableAssets)
      ?.destinationMinBalance;

    if (!token || !destinationMinBalance) return 0n;

    const minBalanceAmount = toBigInt(
      destinationMinBalance.amount,
      token.decimals,
    );

    const balanceForToken = balances?.find(
      (balance) =>
        token && balance.key === destinationMinBalance?.asset.asset.key,
    )?.balance;

    if (balanceForToken !== undefined && balanceForToken < minBalanceAmount) {
      return minBalanceAmount;
    }

    return 0n;
  }
}
