import { Group, Stack, Text, UnstyledButton } from '@mantine/core';
import { Avatar } from 'components/Avatar';
import { useAddress } from 'hooks/useAddress';
import { useThemeColors } from 'hooks/useThemeColors';
import { useTranslation } from 'next-i18next';
import { useSubstrateExtension } from 'recoil/substrateExtension';
import { sliceEllipsis } from 'utils/text';
import { useDisconnect } from 'wagmi';
export const ConnectedAvatar = () => {
  const {
    t
  } = useTranslation();
  const address = useAddress();
  const {
    disconnect
  } = useDisconnect();
  const {
    setSelectedExtension
  } = useSubstrateExtension();
  const {
    themeColors
  } = useThemeColors();
  return <Group align={'center'} style={theme => ({
    height: 52,
    padding: theme.spacing.xs,
    backgroundColor: themeColors.whiteDark,
    borderRadius: theme.radius.md
  })} data-sentry-element="Group" data-sentry-component="ConnectedAvatar" data-sentry-source-file="ConnectedAvatar.tsx">
      <Avatar address={address} size={26} data-sentry-element="Avatar" data-sentry-source-file="ConnectedAvatar.tsx" />
      <Stack gap={4} data-sentry-element="Stack" data-sentry-source-file="ConnectedAvatar.tsx">
        <Text size={'10px'} fw={'bold'} data-sentry-element="Text" data-sentry-source-file="ConnectedAvatar.tsx">
          {sliceEllipsis(address, 8, 6)}
        </Text>
        <UnstyledButton aria-label={t('disconnect')} onClick={() => {
        disconnect();
        setSelectedExtension(undefined);
      }} data-sentry-element="UnstyledButton" data-sentry-source-file="ConnectedAvatar.tsx">
          <Text size={'10px'} c={themeColors.secondaryTexts} data-sentry-element="Text" data-sentry-source-file="ConnectedAvatar.tsx">
            {t('disconnect')}
          </Text>
        </UnstyledButton>
      </Stack>
    </Group>;
};