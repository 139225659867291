import { Stack, Text } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { OffsiteLink } from 'components/OffsiteLink';
import { autoClose } from 'hooks/notifications/notifications.constants';
import { useMoonChain } from 'hooks/useMoonChainConfig';
import { useTranslation } from 'next-i18next';
import { useCallback, useMemo } from 'react';
import { hasName } from 'utils/types';
import { Connector, useAccount } from 'wagmi';
export enum TransactionAction {
  AUTO_COMPOUND = 'Auto-compound',
  ADD_PROXY = 'Add proxy',
  REMOVE_PROXY = 'Remove proxy',
  REMOVE_ALL_PROXIES = 'Remove all proxies',
  BOND = 'Bond',
  CANCEL_REQUEST = 'Cancel request',
  CLAIM = 'Claim',
  CONNECTION = 'Connection',
  DECREASE = 'Decrease',
  STAKING_DELEGATION = 'Staking delegation',
  EXECUTION = 'Execution',
  REMOVE_VOTE = 'Remove vote',
  REVOKE = 'Revoke',
  SIGN = 'Sign',
  UNLOCK = 'Unlock',
  VOTE = 'Vote',
  CREATE_PROPOSAL = 'Create proposal',
  CREATE_PREIMAGE = 'Create preimage',
  REDEEM = 'Redeem',
  BRIDGE = 'Bridge',
}
export type ConnectArgs = {
  /** Chain ID to connect to */
  chainId?: number;
  /** Connector to connect */
  connector: Connector;
};
const USER_REJECTED_ERROR = 'UserRejectedRequestError';
export const useWalletNotifications = (action?: TransactionAction) => {
  const {
    t
  } = useTranslation();
  const {
    connector
  } = useAccount();
  const {
    id,
    explorerUrls
  } = useMoonChain();
  const renderContentWithLink = useCallback((hash?: string, explorer?: string, message?: string) => {
    if (!hash) return message;
    const explorerUrl = explorer || explorerUrls[0];
    const link = `${explorerUrl}/tx/${hash}`;
    return <Stack>
          <OffsiteLink label={t('viewTransactionInExplorer')} link={link} size={'xs'} />
          {message && <Text size={'sm'}>{message}</Text>}
        </Stack>;
  }, [t, explorerUrls]);
  return useMemo(() => ({
    notifyTransactionSent: (hash?: string, explorer?: string) => showNotification({
      title: t('transactions.transactionSent', {
        action
      }),
      message: renderContentWithLink(hash, explorer, t('transactions.waitingForConfirmation')),
      color: 'grape',
      autoClose
    }),
    notifyTransactionConfirmed: (hash?: string, explorer?: string) => showNotification({
      title: t('transactions.transactionConfirmed', {
        action
      }),
      message: renderContentWithLink(hash, explorer),
      color: 'green',
      autoClose
    }),
    failedToAddAsset: (asset?: string) => showNotification({
      title: t('notifications.failedToAddAsset.title'),
      message: t('notifications.failedToAddAsset.message', {
        asset,
        wallet: connector?.name
      }),
      color: 'red',
      autoClose
    }),
    notifyTransactionError: (error: Error, args?: ConnectArgs) => {
      console.error(error);
      if (hasName(error.cause) && error.cause.name === USER_REJECTED_ERROR) {
        showNotification({
          title: t(`wagmi.errors.${USER_REJECTED_ERROR}.title`),
          message: t(`wagmi.errors.${USER_REJECTED_ERROR}.message`, {
            connector: args?.connector?.name || connector?.name
          }),
          color: 'red',
          autoClose
        });
        return;
      }
      if (error.message) {
        showNotification({
          title: t(`wagmi.errors.${error.name}.title`, t(`wagmi.errors.default.title`)),
          message: error.message,
          color: 'red',
          autoClose
        });
        return;
      }
      showNotification({
        title: t(`wagmi.errors.${error.name}.title`, t(`wagmi.errors.default.title`)),
        message: t(`wagmi.errors.${error.name}.message`, t(`wagmi.errors.default.message`), {
          chainId: args?.chainId || id,
          connector: args?.connector?.name || connector?.name
        }),
        color: 'red',
        autoClose
      });
    }
  }), [t, action, connector?.name, id, renderContentWithLink]);
};